import React, { useState } from 'react';
import WorkshopsEmailTable from './WorkshopsEmailTable';
import { NotificationDetail } from '../../models/Workshops';
import { searchErrors } from '../../helperFunctions/SearchHelper';
import { WorkshopsServiceClient } from '../../clients/WorkshopsClient';
import { Button, MessageBar, TextField } from '../harmony';

export function WorkshopsEmailTracker(props: any) {
    const [claimId, setClaimId] = useState<string>('');
    const [engagementId, setEngagementId] = useState<string>('');
    const [searchMsg, setSearchMsg] = useState<string>("");
    const [showError, setShowError] = useState<boolean>(false);
    const [notificationDetails, setNotificationDetails] = useState<NotificationDetail[]>([]);
    const workshopsServiceClient = new WorkshopsServiceClient();

    const searchNotificationDetails = async () => {

        //clear previous search
        setShowError(false);
        setSearchMsg("");
        setNotificationDetails([])

        // search with provided parameters.
        try {
            const emailNotifications: NotificationDetail[] = await workshopsServiceClient.searchNotificationDetails(claimId, engagementId, props.accessToken)
            if (emailNotifications.length === 0) {
                setSearchMsg("No notification status were found for this query");
                setShowError(true);
            } else {
                setNotificationDetails(emailNotifications);
                setSearchMsg(`${emailNotifications.length} notification status(es) were found for this query`);
            }
        }
        catch (err: any) {
            setShowError(true);
            setSearchMsg(searchErrors(err?.response?.status));
        }
    };
    const searchDisabled: boolean = engagementId.trim().length === 0 || claimId.trim().length === 0;

    return <React.Fragment>
        <table className="table table-plain">
            <tbody>
                <tr>
                    <td className="remove-bottom-margin">
                        <TextField
                            value={claimId}
                            label="Claim Id"
                            onHeChange={(e: any) => { setClaimId(e.target.value.trim()) }}
                            onHeInput={(e: any) => { setClaimId(e.target.value.trim()) }}
                            required
                        />
                    </td>
                    <td>
                        <TextField
                            value={engagementId}
                            label="Engagement ID"
                            onHeChange={(e: any) => { setEngagementId(e.target.value.trim()) }}
                            onHeInput={(e: any) => { setEngagementId(e.target.value.trim()) }}
                            required
                        >
                        </TextField>
                    </td>
                    <td>
                        <Button
                            // style={{ width: '100%', backgroundColor: '#00a2ed', height: '100%', color: 'white', outline: 'transparent' }}
                            className="padding-top"
                            appearance='primary'
                            disabled={searchDisabled}
                            onClick={() => searchNotificationDetails()}
                        >{"Search"}</Button>
                    </td>
                </tr>
            </tbody>
        </table>
        {searchMsg.length > 0 && <MessageBar open appearance={showError ? "error" : "success"}>{searchMsg}</MessageBar>}
        {notificationDetails.length > 0 && <WorkshopsEmailTable notificationDetails={notificationDetails} />}
    </React.Fragment>
}
