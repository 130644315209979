import axios from "axios";
import { NotificationDetail } from "../models/Workshops";


export class WorkshopsServiceClient {

    async searchEligibility(partnerId: string, customerId: string, engagementId: string, accessToken: string) {
        const response = axios.get(
            '/api/workshops/getEligibility',
            {
                params: {
                    partnerId: partnerId,
                    customerId: customerId,
                    engagementId: engagementId
                },
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            }
        )
        return response;
    };

    async searchNotificationDetails(claimId: string, engagementId: string, accessToken: string): Promise<NotificationDetail[]> {
        const response = await axios.get<NotificationDetail[]>(
            '/api/workshops/getEmailDetails',
            {
                params: {
                    claimId: claimId,
                    engagementId: engagementId
                },
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            }
        )

        return response.data;
    }

}
