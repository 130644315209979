import React, { useState } from 'react';
import { DeleteRelationshipRequest, Endpoint, Page, Relationship, RelationshipHeaders, Role, TargetRelationshipHeaders } from '../../models/Relationships';
import { CSVLink } from 'react-csv';
import { RelationshipServiceClient } from '../../clients/RelationshipClients';
import { Row } from '@harmony/enablers/components/data-grid/data-grid';
import { Button, DataGrid, Dialog, Icon, TextField } from '../harmony';
import { searchErrors } from '../../helperFunctions/SearchHelper';
import RelationshipExpand from './RelationshipExpand';

const RelationshipTable = (props: {
    pages: Page[],
    setPages: Function,
    setPageNumber: Function,
    setNextPageLink: Function,
    pageNumber: number,
    nextPageLink: string,
    endpoint: Endpoint,
    roles: string[],
    idToken: string,
    accessToken: string,
    id: string,
}) => {
    const [search, setSearch] = useState<string>('');
    const [showJson, setShowJson] = useState<boolean>(false);
    const [relationshipToShow, setRelationshipToShow] = useState<Relationship>();
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const [errorMsg, setErrorMsg] = useState<string>("");

    const relationshipServiceClient = new RelationshipServiceClient();

    // Get the next page of data if the user is at the end of the current page.
    const getNextPage = async () => {
        if (props.pageNumber + 1 === props.pages.length) {
            try {
                const responsee = await relationshipServiceClient.getNextPage(props.nextPageLink, props.accessToken)
                props.pages.push(responsee.data);
                props.setNextPageLink(responsee.data.nextPageLink);
            }
            catch {
                // add error handling
            }
        }
        else {
            props.setNextPageLink(props.pages[props.pageNumber + 1].nextPageLink);

        }
        props.setPageNumber(props.pageNumber + 1);
    };

    // delete a CSP relationship
    const deleteRelationship = async (relationship: Relationship) => {
        setErrorMsg("");
        setIsError(false);
        setOpenModal(false);

        const request: DeleteRelationshipRequest = {
            relationshipId: relationship.relationshipId,
            sourceTenantId: relationship.source?.tenantId,
            targetTenantId: relationship.target?.tenantId,
            relationshipType: relationship.relationshipType,
            program: relationship.program,
        }

        try {

            const response = await relationshipServiceClient.deleteRelationship(request, props.accessToken, props.idToken);


            if (!response.data.error) {
                props.pages[props.pageNumber].relationships = props.pages[props.pageNumber]?.relationships.filter((curentRelationship) => {
                    return JSON.stringify(curentRelationship) !== JSON.stringify(relationship)
                });
                props.setPages([...props.pages])
            }
        }
        catch (err: any) {
            setIsError(true);
            setErrorMsg(searchErrors(err?.response?.status));
        }
    }

    // Create the Datagrid columns.
    const columns = [

        { field: 'sourceFriendlyName', content: 'Source Name' },
        { field: 'targetFriendlyName', content: 'Target Name' },
        { field: 'sourceTenantId', content: 'Source ID' },
        { field: 'targetTenantId', content: 'Target ID' },
    ];

    // only show delete column if the relationship is CSP
    if (props.roles && props.roles.includes(Role)) {
        columns.push({ field: 'deleteRelationship', content: 'Delete' });
    }

    // Compute what to show for the id column. If the relationship is CSP, ISV or GDAP, show the tenantId, otherwise show the accountId
    const getRelationshipSourceTargetIds = (relationship: Relationship) => {
        const sourceId = ['CSP', 'ISV', "GDAP"].includes(relationship.program) ? relationship.source?.tenantId : relationship.source?.accountId ?? "-";
        const targetId = ['CSP', 'ISV', "GDAP"].includes(relationship.program) ? relationship.target?.tenantId : relationship.target?.accountId ?? "-";

        return { sourceId, targetId };
    }

    // Create the rows.
    const rows = props.pages[props.pageNumber]?.relationships.filter((relationship: any) => {
        return (props.endpoint === 'source') ?
            !relationship.target || !relationship.target.tenantId || relationship.target.tenantId?.includes(search) :
            (!relationship.source && !relationship.source.tenantId) || relationship.source.tenantId?.includes(search)
    }).map((relationship: Relationship, index: number) => {

        const { sourceId, targetId } = getRelationshipSourceTargetIds(relationship);

        const row: Row = {
            id: index,
            cells: {
                sourceFriendlyName: relationship.sourceFriendlyName ?? "-",
                targetFriendlyName: relationship.targetFriendlyName ?? "-",
                sourceTenantId: sourceId,
                targetTenantId: targetId,
                deleteRelationship: "-"
            },
            customData: { relationship: relationship },

        }
        return row;
    });

    return (
        <React.Fragment>
            <Dialog
                open={openModal}
                onHeHide={() => setOpenModal(false)}
                onHeAfterHide={() => setOpenModal(false)}
            >
                <h1 style={{ alignItems: 'center' }}>
                    {"Are you sure?"}
                </h1>
                <p>
                    {"Do you really want to delete this record? This process cannot be undone"}
                </p>
                <div style={{ display: 'inline-flex', justifyContent: 'center', width: '100%', margin: 5 }}>
                    <Button onClick={() => {
                        setOpenModal(false)
                    }}
                    >
                        Cancel
                    </Button>
                    <Button onClick={() => {
                        deleteRelationship(relationshipToShow as Relationship);
                        setOpenModal(false)
                    }}
                        appearance='secondary'
                        style={{ backgroundColor: 'red', marginLeft: '10%' }}
                    >
                        {"Confirm Delete"}
                    </Button>
                </div>
            </Dialog>
            {showJson && relationshipToShow && <RelationshipExpand
                relationship={relationshipToShow}
                setRelationship={setRelationshipToShow}
                allRelationships={props.pages[props.pageNumber].relationships}
                setPages={props.setPages}
                page={props.pageNumber}
                entity={props.pages}
                roles={props.roles}
                idToken={props.idToken}
                accessToken={props.accessToken}
                showPanel={showJson}
                setShowPanel={setShowJson}
            />
            }
            <DataGrid
                rows={rows}
                columns={columns}
                select="single"
                select-on-click
                label="Select a row to expand"
                onHeRowSelectChange={(event) => {
                    setRelationshipToShow((event.detail.customData as any).relationship as Relationship);
                    setShowJson(true);
                }}
            >
                <span slot="data-grid-controls" className="table-commands" style={{ display: "flex", paddingTop: "1em" }}>
                    <Button
                        key={"button-export"}
                        appearance="secondary"
                        style={{ marginRight: "1em" }}
                        disabled={props.pages[props.pageNumber]?.relationships?.length === 0}
                    >
                        <CSVLink
                            key={"csv-export"}
                            data={props.pages[props.pageNumber].relationships}
                            headers={(props.pages[props.pageNumber]?.relationships[0]?.program === 'GDAP') ? RelationshipHeaders : [...RelationshipHeaders, ...TargetRelationshipHeaders]}
                            filename={'Relationship_' + props.id + '.csv'}
                        >
                            {"Export Data"} <Icon slot="end" name='download' />
                        </CSVLink>
                    </Button>
                    <TextField
                        key={"search"}
                        className='md-width'
                        placeholder={`Search by ${(props.endpoint === 'target') ? 'Source' : 'Target'} ID...`}
                        onHeChange={(e: any) => setSearch(e.target.value)}
                        onHeInput={(e: any) => setSearch(e.target.value)}>
                        <Icon slot='start' name='search'></Icon>
                    </TextField>
                </span>
                {rows.map((item: any, index: number) => {
                    return <React.Fragment key={`map-${index}`}>
                        <span slot={`deleteRelationship-${index}`}>
                            {(item.customData.relationship.program !== 'GDAP' && props.roles && props.roles.includes(Role)) &&
                                <Button appearance='stealth' onClick={() => { setOpenModal(true); setRelationshipToShow(item.customData.relationship) }}>
                                    <Icon name='delete' style={{ color: 'red' }} />
                                </Button>
                            }
                        </span>
                    </React.Fragment>
                })
                }
            </DataGrid>
            {(props.pageNumber !== 0) &&
                <Button
                    style={{ backgroundColor: '#d3d3d3', height: '50px' }}
                    onClick={() => {
                        props.setNextPageLink(props.pages[props.pageNumber - 1].nextPageLink);
                        props.setPageNumber(props.pageNumber - 1)

                    }}
                >
                    Previous
                </Button>
            }
            {(props.nextPageLink || props.pageNumber !== 0) ?
                `Page ${props.pageNumber + 1}` :
                null}
            {(props.nextPageLink) &&
                <Button
                    style={{ backgroundColor: '#d3d3d3', height: '50px' }}
                    onClick={getNextPage}
                >
                    Next
                </Button>
            }
        </React.Fragment>
    )
}

export default RelationshipTable
