import React from 'react';
import { PublicClientApplication } from '@azure/msal-browser';
import Axios from 'axios';
import { AuthProviderConfig } from './AuthProviderConfig';

interface AuthProviderState {
    error: any;
    isAuthenticated: boolean;
    user: any;
    roles: string[];
    idToken: string;
    accessToken: string;
    pictureUrl: string;
    name: string;
    displayName: string;
    jobTitle: string;
    email: string;
}

export default function AuthProvider(WrappedComponent: any) {
    return class extends React.Component<any, AuthProviderState> {
        private publicClientApplication: PublicClientApplication;

        constructor(props: any) {
            super(props);
            this.state = {
                error: null,
                isAuthenticated: false,
                user: {},
                roles: [],
                idToken: '',
                accessToken: '',
                pictureUrl: '',
                name: '',
                displayName: '',
                jobTitle: '',
                email: ''
            };

            // Initialize the MSAL application object
            this.publicClientApplication = new PublicClientApplication({
                auth: {
                    clientId: AuthProviderConfig.appId,
                    redirectUri: AuthProviderConfig.redirectUri,
                    authority: AuthProviderConfig.authority
                },
                cache: {
                    cacheLocation: AuthProviderConfig.cacheStorage
                }
            });
        }

        awaitResolve = async () => await this.publicClientApplication.handleRedirectPromise();

        // 2
        // Once redirected back to page after login
        async componentDidMount() {
            // process AAD response
            await this.publicClientApplication.handleRedirectPromise();

            // checks that at least one account is returned
            const accounts = this.publicClientApplication.getAllAccounts();
            if (accounts && accounts.length > 0) {
                this.getUserProfile();
            }
        }

        // 3
        // gets the actual bearer token
        async getUserProfile() {
            try {
                var accessToken = await this.getAccessToken(AuthProviderConfig.scopes);

                if (accessToken) {
                    // Get picture
                    Axios.get('https://graph.microsoft.com/v1.0/me/photo/$value', {
                        headers: {
                            'Authorization': 'Bearer ' + accessToken
                        },
                        responseType: 'blob'
                    }).then(response => {
                        const url = window.URL || window.webkitURL;
                        const blobUrl = url.createObjectURL(response.data);

                        this.setState({
                            isAuthenticated: true,
                            pictureUrl: blobUrl,
                            error: null
                        });
                    })

                    // Get name of user
                    Axios.get('https://graph.microsoft.com/v1.0/me', {
                        headers: {
                            'Authorization': 'Bearer ' + accessToken
                        }
                    }).then(response => {
                        const name = JSON.parse(JSON.stringify(response.data)).givenName;
                        const displayName = JSON.parse(JSON.stringify(response.data)).displayName;
                        const email = JSON.parse(JSON.stringify(response.data)).mail;
                        const jobTitle = JSON.parse(JSON.stringify(response.data)).jobTitle;

                        this.setState({
                            isAuthenticated: true,
                            name: name,
                            displayName,
                            jobTitle,
                            email,
                            error: null
                        });
                    });
                }
            }
            catch (err: any) {
                this.setState({
                    isAuthenticated: false,
                    user: {},
                    error: this.normalizeError(err)
                });
            }
        }

        // 1
        // Login Page
        // Redirect user to new page for login
        // "Select account" will interrupt single sign-on by providing an account selection 
        // experience listing all the accounts in session or an option to choose a different account altogether.
        async login() {
            try {
                // redirects to AAD login page and requests for metadata of token
                await this.publicClientApplication.loginRedirect(
                    {
                        scopes: AuthProviderConfig.scopes,
                        prompt: "select_account"
                    });
            }
            catch (err: any) {
                this.setState({
                    isAuthenticated: false,
                    user: {},
                    error: this.normalizeError(err)
                });
            }
        }

        normalizeError(error: string | Error): any {
            var normalizedError = {};

            if (typeof (error) === 'string') {
                var errParts = error.split('|');
                normalizedError = errParts.length > 1 ?
                    { message: errParts[1], debug: errParts[0] } :
                    { message: error };
            }
            else {
                normalizedError = {
                    message: error.message,
                    debug: JSON.stringify(error)
                };
            }
            return normalizedError;
        }

        // Logout Page
        logout() {
            this.publicClientApplication.logoutRedirect();
        }

        // Get bearer token
        async getAccessToken(scopes: string[]): Promise<string> {
            try {
                const accounts = this.publicClientApplication
                    .getAllAccounts();

                if (accounts.length <= 0) throw new Error('login_required');

                // Get the access token silently
                // If the cache contains a non-expired token, this function
                // will just return the cached token. Otherwise, it will
                // make a request to the Azure OAuth endpoint to get a token
                var silentResult: any = await this.publicClientApplication
                    .acquireTokenSilent({
                        scopes: scopes,
                        account: accounts[0]
                    });
                this.setState({
                    roles: silentResult.idTokenClaims.roles,
                    idToken: silentResult.idToken,
                    accessToken: silentResult.accessToken,
                });

                return silentResult.accessToken

            } catch (err: any) {
                // If a silent request fails, it may be because the user needs
                // to login or grant consent to one or more of the requested scopes
                if (this.isInteractionRequired(err)) {
                    var interactiveResult: any = await this.publicClientApplication
                        .acquireTokenPopup({
                            scopes: scopes
                        });

                    this.setState({
                        roles: interactiveResult.idTokenClaims.roles,
                        idToken: interactiveResult.idToken,
                        accessToken: interactiveResult.accessToken
                    });

                    return interactiveResult.accessToken
                }
                else {
                    throw err;
                }
            }
        }

        render() {
            this.awaitResolve();
            return <WrappedComponent
                error={this.state.error}
                isAuthenticated={this.state.isAuthenticated}
                login={() => this.login()}
                logout={() => this.logout()}
                getAccessToken={(scopes: string[]) => this.getAccessToken(scopes)}
                accessToken={this.state.accessToken}
                roles={this.state.roles}
                idToken={this.state.idToken}
                userImageUrl={this.state.pictureUrl}
                name={this.state.name}
                displayName={this.state.displayName}
                jobTitle={this.state.jobTitle}
                setError={(message: string, debug: string) => this.setErrorMessage(message, debug)}
                email={this.state.email}
                {...this.props} />;
        }

        setErrorMessage(message: string, debug: string) {
            this.setState({
                error: { message: message, debug: debug }
            });
        }

        isInteractionRequired(error: Error): boolean {
            if (!error.message || error.message.length <= 0) {
                return false;
            }

            return (
                error.message.indexOf('consent_required') > -1 ||
                error.message.indexOf('interaction_required') > -1 ||
                error.message.indexOf('login_required') > -1 ||
                error.message.indexOf('no_account_in_silent_request') > -1
            );
        }
    }
}