export interface Page {
    relationships: Relationship[],
    nextPageLink: string
}

export interface Relationship {
    relatedThrough: Account[] | {}[],
    state: State,
    startDate: string,
    endDate: string,
    createdDateTime: string,
    sourceFriendlyName: string,
    targetFriendlyName: string,
    relationshipId: string,
    domainName: string,
    fpocaid: string,
    extendedProperties: { key: string, value: string }[],
    source: Account,
    target: Account,
    relationshipType: RelationshipType,
    program: ProgramType
}

export interface ExtendedProperty {
    key: string,
    value: string
}

export interface Account {
    accountId: string,
    accountType: AccountType,
    organizationId: string,
    billingGroupId: string,
    tenantId: string,
    projectId?: string
}

export enum State {
    Active = "Active",
    Inactive = "Inactive",
    Draft = "Draft"
}

export enum RelationshipType {
    PartnerToCustomer = "PartnerToCustomer",
    PartnerToIndirectReseller = "PartnerToIndirectReseller",
    IndirectResellerToCustomer = "IndirectResellerToCustomer",
    LeadAffiliate = "LeadAffiliate",
    PartnerIndirect = "PartnerIndirect",
    ISVToCustomer = "ISVToCustomer",
    GranularAdministration = "GranularAdministration"
}

export enum ProgramType {
    CSP = "CSP",
    FedGovIndirect = "FedGovIndirect",
    Direct = "Direct",
    ISV = "ISV",
    MciWorkshop = "MciWorkshop",
    GDAP = "GDAP",
    Unknown = ""
}

export enum AccountType {
    MPN = "MPN",
    TPID = "TPID",
    Tenant = "Tenant",
    unknown = ""
}

export enum Endpoint {
    Source = "source",
    Target = "target"
}

//Defines azureAD role required for user to make updates to relationship entities
export const Role = "Update"


export const RelationshipHeaders = [
    { label: 'State', key: 'state' },
    { label: 'Start Date', key: 'startDate' },
    { label: 'End Date', key: 'endDate' },
    { label: 'Created', key: 'createdDateTime' },
    { label: 'Relationship Id', key: 'relationshipId' },
    { label: 'Source Friendly Name', key: 'sourceFriendlyName' },
    { label: 'Target Frienly Name', key: 'targetFriendlyName' },
    { label: 'Domain Name', key: 'domainName' },
    { label: 'fpoCaid', key: 'fpoCaid' },
    { label: 'Source Account Id', key: 'source.accountId' },
    { label: 'Source Account Type', key: 'source.accountType' },
    { label: 'Source Organization Id', key: 'source.organizationId' },
    { label: 'Source Billing Group Id', key: 'source.billingGroupId' },
    { label: 'Source Tenant Id', key: 'source.tenantId' },
    { label: 'Relationship Type', key: 'relationshipType' },
    { label: 'Program', key: 'program' }
]

export const TargetRelationshipHeaders = [
    { label: 'Target Account Id', key: 'target.accountId' },
    { label: 'Target Account Type', key: 'target.accountType' },
    { label: 'Target Organization Id', key: 'target.organizationId' },
    { label: 'Target Billing Group Id', key: 'target.billingGroupId' },
    { label: 'Target Tenant Id', key: 'target.tenantId' },
]

export const FilterRelationshipType: any = {
    All: ['PartnerToCustomer', 'PartnerToIndirectReseller', 'IndirectResellerToCustomer', 'LeadAffiliate', 'PartnerIndirect', 'ISVToCustomer', 'GranularAdministration'],
    CSP: ["PartnerToCustomer", "PartnerToIndirectReseller", "IndirectResellerToCustomer"],
    FedGovIndirect: ["PartnerIndirect", "PartnerToIndirectReseller", "LeadAffiliate"],
    Direct: ["LeadAffiliate"],
    ISV: ["ISVToCustomer"],
    MciWorkshop: ["PartnerToCustomer"],
    GDAP: ['GranularAdministration'],
    Unknown: []
}

const startDateValue = new Date();
const endDateValue = new Date();
endDateValue.setDate(startDateValue.getDate() + 30);

export const RelationshipBasePlaceholder: any = {
    createdDateTime: "",
    state: State.Active,
    startDate: startDateValue.toISOString(),
    endDate: endDateValue.toISOString(),
    sourceFriendlyName: "",
    targetFriendlyName: "",
    domainName: "",
    fpocaid: "",
    relationshipId: "",
    relationshipType: "",
    program: ""
}

export const EntityIdentifierPlaceHolder: Account = {
    accountId: "",
    accountType: AccountType.unknown,
    organizationId: "",
    billingGroupId: "",
    tenantId: "",
    projectId: ""
}

export const ExtendedPropertyPlaceholder: ExtendedProperty = {
    key: "",
    value: ""
};

export interface DeleteRelationshipRequest {
    relationshipId: string,
    sourceTenantId: string,
    targetTenantId: string,
    relationshipType: RelationshipType,
    program: ProgramType,
}