import React from "react";
import { Select, TextField, Option, Button, Icon } from "../../harmony";
import { Account, ProgramType } from "../../../models/Relationships";

interface EntityIdentifierProps {
    identifier: Account;
    onChanged: (value: Account) => void;
    showDeleteOption: boolean;
    Delete?: (value: Account) => void;
    program: ProgramType;
}

const EntityIdentifier = (entity: EntityIdentifierProps) => {
    const { identifier, onChanged, showDeleteOption, Delete, program } = entity;
    const tenantIdRequired: boolean = [ProgramType.CSP, ProgramType.ISV, ProgramType.GDAP].includes(program.trim() as ProgramType)

    return (
        <>
            <tr>
                <td style={{ paddingRight: "var(--he-spacing-x-large)" }}>
                    <TextField
                        required={tenantIdRequired}
                        label='Tenant ID'
                        value={identifier.tenantId ?? ''}
                        onHeChange={(e: any) => {
                            identifier.tenantId = e.target.value.trim()
                            onChanged(identifier)
                        }}
                        onHeInput={(e: any) => {
                            identifier.tenantId = e.target.value.trim()
                            onChanged(identifier)
                        }}

                    />
                </td>
                <td>
                    <TextField
                        label='Account ID'
                        required={!tenantIdRequired}
                        disabled={false}
                        value={identifier.accountId ?? ''}
                        onHeChange={(e: any) => {
                            identifier.accountId = e.target.value.trim()
                            onChanged(identifier)
                        }}
                        onHeInput={(e: any) => {
                            identifier.accountId = e.target.value.trim()
                            onChanged(identifier)
                        }}

                    />
                </td>
                {
                    showDeleteOption && Delete &&
                    <td >
                        <Button
                            className='padding-top'
                            appearance='stealth'
                            onClick={(e: any) => {
                                Delete(identifier)
                            }}
                            style={{ width: '100%' }}
                        >
                            <Icon name='cancel' style={{ color: "white", backgroundColor: 'red' }}></Icon>
                        </Button>
                    </td>
                }
            </tr>
            <tr >
                <td style={{ paddingRight: "var(--he-spacing-x-large)" }}>
                    <TextField
                        label='Organization ID'
                        required={!tenantIdRequired}
                        value={identifier.organizationId ?? ''}
                        onHeChange={(e: any) => {
                            identifier.organizationId = e.target.value.trim()
                            onChanged(identifier)
                        }}
                        onHeInput={(e: any) => {
                            identifier.organizationId = e.target.value.trim()
                            onChanged(identifier)
                        }}
                    />
                </td>
                <td>
                    <TextField
                        label='Billing Group ID'
                        value={identifier.billingGroupId ?? ''}
                        onHeChange={(e: any) => {
                            identifier.billingGroupId = e.target.value.trim()
                            onChanged(identifier)
                        }}
                        onHeInput={(e: any) => {
                            identifier.billingGroupId = e.target.value.trim()
                            onChanged(identifier)
                        }}
                    />
                </td>
            </tr>
            <tr >
                <td style={{ paddingRight: "var(--he-spacing-x-large)" }}>
                    <TextField
                        label='Project ID'
                        value={identifier.projectId ?? ''}
                        onHeChange={(e: any) => {
                            identifier.projectId = e.target.value.trim()
                            onChanged(identifier)
                        }}
                        onHeInput={(e: any) => {
                            identifier.projectId = e.target.value.trim()
                            onChanged(identifier)
                        }}
                    />
                </td>
                <td>
                    <Select
                        disabled={program !== ProgramType.MciWorkshop}
                        label='Account Type'
                        value={identifier.accountType ?? ''}
                        onHeChange={(e: any) => {
                            identifier.accountType = e.target.value.trim()
                            onChanged(identifier)
                        }}
                        onHeInput={(e: any) => {
                            identifier.accountType = e.target.value.trim()
                            onChanged(identifier)
                        }}
                    >
                        <Option value='MPN'>MPN</Option>
                        <Option value='TPID'>TPID</Option>
                        <Option value='Tenant'>Tenant</Option>
                    </Select>
                </td>
            </tr>
        </>
    );
}


export default EntityIdentifier;