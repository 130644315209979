import React from 'react';
import { Eligibility } from '../../models/Workshops';
import { DataGrid } from '../harmony';
import { Column, Row } from '@harmony/enablers/components/data-grid/data-grid';
import "../../css/Common.css"

const colorForStatus = (status: unknown) => {
    switch (status) {
        case "CustomerConsentExpired":
        case "CustomerConsentDeclined":
        case "ConsentRejected":
        case "Cancelled":
        case "Rejected":
        case "RejectedFinal":
        case "NotSubmittedWorkshopExpired":
        case "PostSubmissionWorkshopExpired":
        case "DisputedWorkshopExpired":
        case "DeletedFinal":
            return "rgb(229, 115, 115)";
        case "CustomerClaimed":
        case "Draft":
        case "Submitted":
        case "Approved":
        case "PartnerActionRequired":
        case "PaymentApproved":
        case "SameAsItWasBeforeTheAction":
            return "rgb(129, 199, 132)";
        case "Disputed":
        case "AwaitingCustomerConsentResponse":
        case "UnderReview":
            return "rgb(255, 183, 77)";
        default:
            return "grey";
    }
};

const WorkshopsEligibilityTable = (props: { eligbilities: Eligibility[] }) => {

    const columns: Column[] = [
        { field: "engagementName", content: "Engagement Name", sortable: true },
        { field: "engagementId", content: "Engagement Id", sortable: true },
        { field: "customerEligibility", content: "Customer Eligibility", sortable: true },
        { field: "mcapWorkshopCompleted", content: "MCAP Workshop Completed", sortable: true },
        { field: "workshopCompleted", content: "Workshop Completed", sortable: true },
    ]

    const rows: Row[] = props.eligbilities.map((eligibility: Eligibility, idx: number) => {
        return {
            id: idx,
            cells: {
                engagementName: eligibility.engagementName,
                engagementId: eligibility.engagementId,
                customerEligibility: eligibility.customerEligibility.toString(),
                mcapWorkshopCompleted: eligibility.mcapWorkshopCompleted.toString(),
                workshopCompleted: Object.entries(eligibility.workshopCompleted ?? {}).map(([claim, status]) => { return `${claim} : ${status}, ` }).join("")
            }
        }
    });

    const sortColumns = (e: CustomEvent) => {
        const column: string = e.detail.sortBy

        rows.sort((a: Row, b: Row) => {
            const fieldA: string = a.cells[column];
            const fieldB: string = b.cells[column];
            const isAscending = e.detail.isAscending;

            if (fieldA > fieldB) {
                return isAscending ? 1 : -1;
            }
            if (fieldA < fieldB) {
                return isAscending ? -1 : 1;
            }
            return 0;
        });

    }

    return (
        <div style={{ height: "auto" }}>
            <DataGrid
                style={
                    {
                        "table-head": "1000px",
                        '--table-head': '240px',
                        '--card-height': '300px',
                    }}
                rows={rows}
                columns={columns}
                onHeSort={sortColumns}
                fixedHeading
            >
                <div slot="data-grid-controls" style={{ justifyContent: "center", display: 'flex' }}>
                    {"ELIGIBILITY CRITERIA"}
                </div>
                {props.eligbilities.map((eligibility: Eligibility, idx: number) => {

                    return Object.entries(eligibility.workshopCompleted ?? {}).map(([claim, status]) => {

                        return <div slot={`workshopCompleted-${idx}`} style={{ margin: "2px", display: 'flex', justifyContent: "center", backgroundColor: colorForStatus(status), borderRadius: "5%" }}>{`${claim}:${status}`}</div>
                    })
                })
                }
                <div id="identified" role="columnheader" style={{ justifyContent: "center", display: 'flex', backgroundColor: '#00A36C' }}>{"placeholder for column style"}</div>
            </DataGrid>
        </div>
    )
}

export default WorkshopsEligibilityTable
