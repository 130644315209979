import * as React from "react";
import { Route, Routes } from "react-router";
import { AuditSearch } from "./pages/AuditSearch";
import { Relationships } from './pages/Relationships';
import { RelationshipMigration } from "./pages/RelationshipMigration";
import { Home } from "./pages/Home";
import { WebHooks } from "./pages/WebHooks";
import { LoginBanner } from "./components/authentication/LoginBanner";
import { Workshops } from './pages/Workshops';
import { AuditSearchGPT } from "./pages/AuditSearchGPT";
import { Catalog } from "./pages/Catalog";
import { RelationshipSearchGPT } from "./pages/RelationshipSearchGPT";

function Routing(props: any) {
    return (
        <div style={{ alignSelf: 'center', width: '80%', marginLeft: 'auto', marginRight: 'auto' }}>

            {props.isAuthenticated &&
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/home" element={<Home />} />
                    <Route path="/AuditSearch" element={<AuditSearch {...props} />} />
                    <Route path="/AuditSearchGPT" element={<AuditSearchGPT {...props} />} />
                    {props.roles !== undefined && props.roles?.includes("Catalog-READ") &&
                        <Route path="/Catalog" element={<Catalog {...props} />} />
                    }
                    <Route path="/WebHooks" element={<WebHooks {...props} />} />
                    <Route path="/Relationships" element={<Relationships {...props} />} />
                    <Route path="/RelationshipSearchGPT" element={<RelationshipSearchGPT {...props} />} />
                    <Route path="/Workshops" element={<Workshops {...props} />} />
                    {props.roles !== undefined && props.roles?.includes("Relationship-BulkMigrate") &&
                        <Route path="/RelationshipMigration" element={<RelationshipMigration {...props} />} />
                    }
                </Routes>
            }
            {!props.isAuthenticated &&
                <Routes>
                    <Route path="/" element={<LoginBanner {...props} />} />
                </Routes>
            }
        </div>
    );
}

export default Routing;