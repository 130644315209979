import React, { useState } from 'react';
import { Endpoint, Page, Relationship, State, Role, ProgramType, ExtendedProperty } from '../../models/Relationships';
import { Button, DatePicker, Icon, MessageBar, Option, Select, TextField } from '../harmony';
import { RelationshipServiceClient } from '../../clients/RelationshipClients';
import { downloadData, searchErrors } from '../../helperFunctions/SearchHelper';
import { FlyInPanel } from '../harmony/FlyInPanel';
import ReactJson from 'react-json-view';
import ExtendedPropertyV2 from './create_relationship/ExtendedProperty';

const RelationshipExpand = (props: {
    relationship: Relationship,
    setRelationship: Function,
    allRelationships: Relationship[],
    setPages: Function,
    page: Number,
    entity: Page[],
    roles: string[],
    idToken: string,
    accessToken: string,
    showPanel: boolean,
    setShowPanel: Function,
}) => {

    const [update, setUpdate] = useState<Endpoint>(Endpoint.Source);
    const [sourceFriendlyName, setSourceFriendlyName] = useState<string>(props.relationship.sourceFriendlyName);
    const [targetFriendlyName, setTargetFriendlyName] = useState<string>(props.relationship.targetFriendlyName);
    const [state, setState] = useState<State>(props.relationship.state);
    const [startDate, setStartDate] = useState<string>(props.relationship.startDate);
    const [endDate, setEndDate] = useState<string>(props.relationship.endDate);
    const [updateStateMsg, setUpdateStateMsg] = useState<string>('');
    const [updateNameMsg, setUpdateNameMsg] = useState<string>('');
    const [updateStateError, setUpdateStateError] = useState<boolean>(false);
    const [updateNameError, setUpdateNameError] = useState<boolean>(false);

    const relationshipservice = new RelationshipServiceClient();

    const [extendedProperties, setExtendedProperties] = useState(props.relationship.extendedProperties);


    const updateState = async () => {
        setUpdateStateError(false);
        setUpdateStateMsg('');

        var newRelationship = { ...props.relationship };
        newRelationship.state = state;
        newRelationship.startDate = startDate;
        newRelationship.endDate = endDate;
        newRelationship.extendedProperties = (extendedProperties.length !== 0) ? extendedProperties : [];
        newRelationship.targetFriendlyName = targetFriendlyName;

        try {
            const response = await relationshipservice.updateState(newRelationship, props.accessToken, props.idToken);

            if (response.data.error) {
                setUpdateStateError(true);
            } else {
                setUpdateStateMsg("Updated Sucessfully!");
                props.setRelationship(newRelationship);
                updateTable();
            }
        }
        catch (err: any) {
            setUpdateStateMsg(searchErrors(err.response.status));
            setUpdateStateError(true);
        }
    };

    const updateTable = () => {
        const idType = getIdType();
        const id = getTenant();
        for (let page of props.entity) {
            for (let relationship of page.relationships) {
                if (idType === 0 && relationship.source && relationship.source.tenantId === id) {
                    relationship.sourceFriendlyName = sourceFriendlyName;
                } else if (relationship.source && relationship.source.accountId === id) {
                    relationship.sourceFriendlyName = sourceFriendlyName;
                }
            }
        }
        let newPages = [...props.entity];
        props.setPages(newPages);
    }
    // Get type of ID for relationship program. 0 is for tenant and 1 is for account id. 
    const getIdType = () => {
        if (props.relationship.program === 'CSP' || props.relationship.program === 'ISV') return 0;
        return 1;
    }

    const getTenant = () => {
        const idType = getIdType();
        if (update === 'source') {
            if (idType === 0) return props.relationship.source.tenantId;
            return props.relationship.source.accountId
        }

        if (idType === 0) return props.relationship.target.tenantId;
        return props.relationship.target.accountId;
    }

    const updateName = async () => {
        setUpdateNameError(false);
        setUpdateNameMsg('');

        const account = props.relationship.source;

        const newName = {
            tenantId: account.tenantId,
            friendlyName: sourceFriendlyName,
            accountId: account.accountId,
            accountType: account.accountType,
            organizationId: account.organizationId,
            program: props.relationship.program,
            relationshipId: props.relationship.relationshipId
        };

        try {
            const response = await relationshipservice.updateName(newName, props.accessToken, props.idToken);

            var data = response.data;
            var newRelationship = { ...props.relationship };

            if (data.error) {
                setUpdateNameError(true);
                setUpdateNameMsg(data.error);
            }
            else {
                setUpdateNameMsg("Updated Sucessfully!");
                newRelationship.sourceFriendlyName = sourceFriendlyName;
                props.setRelationship(newRelationship);
                updateTable();
            }
        }
        catch (err: any) {
            setUpdateNameMsg(searchErrors(err.response.status));
            setUpdateNameError(true);
        }
    }

    return (
        <>
                <FlyInPanel
                    onHeAfterHide={() => { props.setShowPanel(false); props.setRelationship(undefined) }}
                    open={props.showPanel}
                    style={{ "--size": "var(--he-panel-size-medium)" }}
                >
                    {props.relationship &&
                        <>
                            <h4 className='margin-top' style={{ textAlign: 'center' }}>{"Relationship Details"}</h4>

                            <div className="col-md-9 p-0 bg-gray h-md-50 align-items-center">
                                <div className="align-items-center h-md-50 p-0" style={{ overflowY: "auto" }}>
                                    <div style={{ display: "flex", alignItems: "right" }}>
                                        <ReactJson
                                            src={props.relationship}
                                            theme="rjv-default"
                                            collapsed={1}
                                            displayDataTypes={false}
                                            displayObjectSize={false}
                                        />
                                        <Button appearance="stealth"
                                            onClick={() => { downloadData(props.relationship, "relationshipId"); }}

                                        ><Icon name="download" style={{ scale: "2" }} /></Button>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                    {props.roles && props.roles.includes(Role) && props.relationship.program === ProgramType.CSP &&
                        <>
                            <>
                                <h4 className='margin-top' style={{ textAlign: 'center' }}>{"Update Relationship State"}</h4>
                                <MessageBar appearance={updateStateError ? 'error' : "success"} open={updateStateMsg.length > 0}>{updateStateMsg}</MessageBar>
                                <table className="table table-plain">
                                    <tbody>
                                        <tr>
                                            <td className="remove-bottom-margin">
                                                <Select
                                                    label='State'
                                                    value={(state) ? state : ''}
                                                    onHeChange={(e: any) => setState(e.target.value as State)}
                                                >
                                                    <Option value='Active'>{"Active"}</Option>
                                                    <Option value='Inactive'>{"Inactive"}</Option>
                                                    <Option value='Draft'>{"Draft"}</Option>
                                                </Select>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="remove-bottom-margin">
                                                <TextField
                                                    label='Target Friendly Name'
                                                    onHeChange={(e: any) => { setTargetFriendlyName(e.target.value.trim()) }}
                                                    onHeInput={(e: any) => { setTargetFriendlyName(e.target.value.trim()) }}
                                                    value={targetFriendlyName}
                                                >
                                                </TextField>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="remove-bottom-margin">
                                                <DatePicker
                                                    label='Start Date'
                                                    onHeChange={(e: any) => { setStartDate(new Date(e.target.value).toISOString()) }}
                                                    onHeInput={(e: any) => { setStartDate(new Date(e.target.value).toISOString()) }}
                                                    value={startDate}
                                                >
                                                </DatePicker>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="remove-bottom-margin">
                                                <DatePicker
                                                    label='End Date'
                                                    onHeChange={(e: any) => setEndDate(new Date(e.target.value).toISOString())}
                                                    onHeInput={(e: any) => setEndDate(new Date(e.target.value).toISOString())}
                                                    value={endDate}
                                                >
                                                </DatePicker>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="remove-bottom-margin">
                                                <h6>
                                                    {"Extended Properties"}
                                                </h6>

                                                <Button
                                                    appearance='secondary'
                                                    onClick={() => setExtendedProperties([...extendedProperties, { key: '', value: '' }])}
                                                >
                                                    {"Add Property"}
                                                    <Icon name='addmedium' slot='end' style={{ padding: "5px", color: "black" }}></Icon>
                                                </Button>

                                            </td>
                                        </tr>
                                        {
                                            extendedProperties.map((extendedProperty: ExtendedProperty) => {
                                                return (<ExtendedPropertyV2
                                                    extendedProperty={extendedProperty}

                                                    Delete={(property: ExtendedProperty) => {
                                                        const newExtendedProperties = extendedProperties.filter((item) => item !== property)
                                                        setExtendedProperties(newExtendedProperties)
                                                    }
                                                    }
                                                    onChanged={(extendedProperty: ExtendedProperty) => {
                                                        const index = extendedProperties.indexOf(extendedProperty);
                                                        extendedProperties[index] = extendedProperty;
                                                        setExtendedProperties([...extendedProperties])
                                                    }
                                                    }
                                                />)
                                            })
                                        }
                                        <tr>
                                            <td className="remove-bottom-margin">
                                                <Button appearance='primary' onClick={updateState}>{"Update State"}</Button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </>

                            <>
                                <h4 className='margin-top' style={{ textAlign: 'center' }}>{"Update Friendly Name(s)"}</h4>

                                <MessageBar appearance={updateNameError ? 'error' : "success"} open={updateNameMsg.length > 0}>{updateNameMsg}</MessageBar>

                                <table className="table table-plain">
                                    <tbody>
                                        <tr>
                                            <td className="remove-bottom-margin">
                                                <Select
                                                    label='Update'
                                                    value={update ?? ''}
                                                    onHeChange={(e: any) => {
                                                        setUpdate(e.target.value as Endpoint)
                                                    }}
                                                >
                                                    <Option value='source'>Source</Option>
                                                </Select>
                                            </td>
                                            <td className="remove-bottom-margin">
                                                <TextField
                                                    label='Friendly Name'
                                                    value={sourceFriendlyName ?? ''}
                                                    onHeChange={(e: any) => setSourceFriendlyName(e.target.value.trim())}
                                                    onHeInput={(e: any) => setSourceFriendlyName(e.target.value.trim())}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="remove-bottom-margin">
                                                <Button
                                                    appearance='primary'
                                                    onClick={updateName}
                                                >
                                                    {"Update Name"}
                                                </Button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </>
                        </>
                    }
                </FlyInPanel>
        </>
    )
}



export default RelationshipExpand;