import React from 'react';
import { NotificationDetail } from '../../models/Workshops';
import { DataGrid } from '../harmony';

const WorkshopsEmailTable = (props: { notificationDetails: NotificationDetail[] }) => {

    const columns = [
        { field: "notificationId", content: "Notification Id" },
        { field: "emailType", content: "Email Type" },
        { field: "sentTimestamp", content: "Sent Timestamp" },
        { field: "emailSentTo", content: "Email Sent To" },
        { field: "deliveryStatus", content: "Delivery Status" },
        { field: "culture", content: "Culture" },
    ]
    const rows = props.notificationDetails.map((detail: NotificationDetail, index: number) => {
        return {
            id: index,
            cells: {
                notificationId: detail.notificationId,
                emailType: detail.emailType,
                sentTimestamp: detail.sentTimestamp,
                emailSentTo: detail.emailSentTo,
                deliveryStatus: detail.deliveryStatus,
                culture: detail.culture,
            }
        }
    })

    return (
        <React.Fragment>
            <DataGrid columns={columns} rows={rows} />
        </React.Fragment>
    )
}

export default WorkshopsEmailTable
