import React from "react";
import { Relationship } from "../../../models/Relationships";
import { Divider } from "../../harmony/Divider";

interface ReviewProps {
    relationship: Relationship;
    filter: string[];
}

export const ReviewRelationship = (entity: ReviewProps) => {
    const { relationship, filter } = entity;

    const Review = (object: Object) => {
        return Object.entries(object).map(([key, value]) => {
            return (<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                <div style={{ width: '40%', fontWeight: 'bold' }}>{key}</div>
                <div style={{ width: '60%' }}>{value}</div>
            </div>)
        })
    }

    return (
        <>
            {Object.entries(relationship).filter(([field, val]) => !filter.includes(field)).map(([key, value]) => {
                if (["source", "target"].includes(key)) {
                    return <>
                        <Divider />
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div style={{ width: '40%', fontWeight: 'bold' }}>{key}</div>
                            <div style={{ width: '60%' }}>{Review(value)}</div>
                        </div>
                        <br />
                    </>
                }
                else if (["relatedThrough", "extendedProperties"].includes(key)) {
                    return <>
                        <Divider />
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div style={{ width: '40%', fontWeight: 'bold' }}>{key}</div>
                            <div style={{ width: '60%' }}>{
                                value.map((obj: Object, index: number) => {
                                    return <>
                                        <Divider />
                                        {Review(obj)}
                                        <br />
                                    </>
                                })}
                            </div>
                        </div>
                        <br />
                    </>
                }
                else {
                    return (
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div style={{ width: '40%', fontWeight: 'bold' }}>{key}</div>
                            <div style={{ width: '60%' }}>{value}</div>
                        </div>
                    )
                }
            })}

        </>)

}