import * as React from "react";
import { WebHooksSearchList } from "../components/audit&webhooks/WebHooksSearchList";
import { WebHooksSearchBar } from "../components/audit&webhooks/WebHooksSearchBar";
import { downloadData, searchErrors } from "../helperFunctions/SearchHelper";
import { AuditServiceClient } from "../clients/AuditClient";
import { Button, Icon, MessageBar } from "../components/harmony";
import ReactJson from "react-json-view";

export function WebHooks(routerProps: any) {
  const [allRecords, setAllRecords] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  const [selectedRecord, setSelectedRecord] = React.useState<any>("init");
  const [auditSearchListMessage, setAuditSearchListMessage] = React.useState<string>("");
  const auditServiceClient = new AuditServiceClient();

  const onSelect = (record: any) => {
    setSelectedRecord(record);
  };

  const searchAllRecords = async (type: string, partnerId: string) => {
    setAuditSearchListMessage("");
    setLoading(true);
    setIsError(false)
    setAuditSearchListMessage("")

    try {
      const response = await auditServiceClient.getAllWebhooks(type, routerProps.accessToken);
      const tempRecordsList: any[] = [];

      for (var i = 0; i < response.data.length; i++) {
        if (partnerId === "" || partnerId === undefined || response.data[i].id === partnerId) {
          tempRecordsList.push(response.data[i]);
        }
      }
      setAllRecords(tempRecordsList);

      if (Object.keys(tempRecordsList).length > 0) {
        setAuditSearchListMessage("Showing " + response.data.length + " WebHook Records");
      } else {
        setAuditSearchListMessage("No WebHook Records Found");
      }
    }
    catch (err: any) {
      setIsError(true)
      setAuditSearchListMessage(searchErrors(err?.response?.status));
    }
    finally {
      setLoading(false);
    }
  };

  return (
    <>
      <WebHooksSearchBar
        onSearch={searchAllRecords}
        partnerIDParameter={''}
        typeParameter={''}
        allRecords={allRecords}
      />
      {auditSearchListMessage.length > 0 && <MessageBar open appearance={isError ? "error" : "success"}>{auditSearchListMessage}</MessageBar>}
      <div className="d-md-flex h-md-100 align-items-center">
        <div className="col-md-3 p-0 bg-white h-md-100" style={{ overflowY: "auto", height: "80vh" }}>
          <WebHooksSearchList
            recordToShow={selectedRecord}
            allRecords={allRecords}
            onSelect={onSelect}
            loading={loading}
            auditSearchListMessage={auditSearchListMessage}
            isError={isError}
          />
        </div>
        <div className="col-md-9 p-0 bg-gray h-md-100 align-items-center">
          <div className="align-items-center h-md-50 p-0" style={{ height: "80vh", backgroundColor: "#edebe9", overflowY: "auto" }}>
            <div style={{ display: "flex", alignItems: "right", margin: "var('--he-spacing-small')" }}>
              <ReactJson
                src={allRecords.find((record: any) => record.id === selectedRecord)}
                theme="rjv-default"
                collapsed={1}
                displayDataTypes={false}
                displayObjectSize={false}
              />
              <Button appearance="stealth"
                onClick={() => { downloadData(allRecords.find((record: any) => record.id === selectedRecord), "id"); }}

              ><Icon name="download" style={{ scale: "2" }} /></Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
