import * as React from 'react';
import { Scope } from "./Scope";
import { pagination } from "@harmony/enablers/react";

export const Pagination = Scope.forReact(pagination);

export const usePagination = <T,>(data: T[], pageSize: number): [T[], number, number, (event: any) => void] => {
    const [currentPage, setCurrent] = React.useState(1);
    const totalPages = Math.ceil(data.length / pageSize);

    const getcurrentPage = (): number => {
        if (currentPage === 1 || totalPages >= currentPage) {
            return currentPage;
        }
        else {
            // reset to first page when the data reset
            setCurrent(1);
            return 1;
        }

    }

    return [data.slice().splice((currentPage - 1) * pageSize, pageSize), totalPages, getcurrentPage(), (event: any) => setCurrent(event.detail)];
};
