import React from 'react';
import { Button, Icon, TextField } from '../../harmony';
import { ExtendedProperty } from '../../../models/Relationships';

interface EntityIdentifierProps {
    extendedProperty: ExtendedProperty;
    onChanged: (value: ExtendedProperty) => void;
    Delete: (value: ExtendedProperty) => void;
}

const ExtendedPropertyV2 = (props: EntityIdentifierProps) => {
    const { extendedProperty, onChanged, Delete } = props;

    return (
        <div>
            <tr>
                <td>
                    <TextField
                        required
                        label='Key'
                        value={extendedProperty?.key ?? ''}
                        onHeChange={(e: any) => {
                            extendedProperty.key = e.target.value
                            onChanged(extendedProperty)
                        }}
                        onHeInput={(e: any) => {
                            extendedProperty.key = e.target.value
                            onChanged(extendedProperty)
                        }}
                    />
                </td>
                <td>
                    <TextField
                        required
                        label='Value'
                        value={extendedProperty.value ?? ''}
                        onHeChange={(e: any) => {
                            extendedProperty.value = e.target.value
                            onChanged(extendedProperty)
                        }}
                        onHeInput={(e: any) => {
                            extendedProperty.value = e.target.value
                            onChanged(extendedProperty)
                        }}
                    />
                </td>
                <td className="remove-bottom-margin">
                    <Button
                        appearance='stealth'
                        className='padding-top'
                        style={{ outline: 'none' }}
                        onClick={(e: any) => Delete(extendedProperty)}
                    >
                        <Icon name="delete" style={{ color: 'red' }} />
                    </Button>
                </td>
            </tr>

        </div>
    )
}

export default ExtendedPropertyV2;
