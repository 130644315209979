export const AuthProviderConfig = {
    appId: (global.location.host.includes("ppe") || global.location.host.includes("localhost")) ? '8e228dab-1ca8-4681-b9a2-ac3bb1302f99' : '879f00c6-ec1a-4851-9653-ac5b180b7d9e',
    redirectUri: window.location.origin,
    cacheStorage: "sessionStorage",
    authority: 'https://login.microsoftonline.com/72f988bf-86f1-41af-91ab-2d7cd011db47',
    browserChoice: window.navigator.userAgent.indexOf("Edge") > -1 || window.navigator.userAgent.indexOf("IE") > -1,
    scopes: [
        'user.read',
    ]
};
