import { TextField, Button } from "../components/harmony";
import * as React from "react";
import ReactJson from "react-json-view";
import { progressRing } from '@harmony/enablers/react';
import { Scope } from "../components/harmony/Scope";
import { CatalogServiceClient } from "../clients/CatalogClient";

export function Catalog(routerProps: any) {
    const [cacheResponse, setCacheResponse] = React.useState<any>([]);
    const [loading, setLoading] = React.useState(false);
    const [cacheKey, setCacheKey] = React.useState("");

    const ProgressRing = Scope.forReact(progressRing);
    const catalogServiceClient = new CatalogServiceClient();

    const executeQuery = async () => {

        try {
            setCacheResponse([]);
            const response = await catalogServiceClient.getCatalogCacheResponse(cacheKey, routerProps.accessToken);
            if (response.data.length !== 0) {
                setCacheResponse(response.data);
            }
            setLoading(false);
        }
        catch (err: any) {
            console.log(err);
        }
    };

    return (
        <>
            <table className="table">
                <tbody>
                    <tr>
                        <td className="remove-bottom-margin">
                            <TextField
                                style={{ fontSize: 50 }}
                                label='CacheKey'
                                onHeInput={(e: any) => setCacheKey(e.target.value)}
                            />
                            <br />
                        </td>
                        <td className="remove-bottom-margin">
                            <Button
                                className="padding-top"
                                type='submit'
                                disabled={cacheKey === ""}
                                appearance="primary"
                                onClick={() => {
                                    setLoading(true)
                                    executeQuery()
                                }}
                            >Search</Button>
                        </td>
                    </tr>
                </tbody>
            </table>

            <div hidden={!loading}>
                <ProgressRing indeterminate></ProgressRing>
            </div>
            <div className="col-md-15 p-0 bg-gray h-md-100 align-items-center" hidden={loading}>
                <div className="align-items-center h-md-100 p-0" style={{ height: "80vh", backgroundColor: "#edebe9", overflowY: "auto" }}>
                    <div style={{ display: "flex", alignItems: "right", margin: "var('--he-spacing-small')" }}>
                        <ReactJson
                            src={cacheResponse}
                            theme="rjv-default"
                            collapsed={2}
                            displayDataTypes={false}
                            displayObjectSize={false}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}