import React from "react";
import { Select, TextField, Option } from "../../harmony";
import { FilterRelationshipType, ProgramType, Relationship, RelationshipType } from "../../../models/Relationships";
import { DisableNextWizardButton } from "../RelationshipCreate";

interface BaseRelationshipProps {
    identifier: Relationship;
    onChanged: (value: Relationship) => void;
    isNextButtonDisabled: DisableNextWizardButton;
    setIsNextButtonDisabled: (value: DisableNextWizardButton) => void;
}

export const BaseRelationship = (entity: BaseRelationshipProps) => {
    const { identifier, onChanged, setIsNextButtonDisabled, isNextButtonDisabled } = entity;

    const baseRelationshipFieldsDisabled = (): boolean => {
        return identifier.state.toString() === '' || identifier.program.trim() === '' || identifier.relationshipType.trim() === ''
    }

    return (
        <>
            <Select
                value={identifier.state}
                label='State'
                onHeChange={(e: any) => {
                    identifier.state = e.target.value
                    onChanged(identifier)
                    setIsNextButtonDisabled({ ...isNextButtonDisabled, BaseDetailsDisabled: baseRelationshipFieldsDisabled() })
                }}
                required
            >
                <Option value='Active'>Active</Option>
                <Option value='Inactive'>Inactive</Option>
                <Option value='Draft'>Draft</Option>
            </Select>
            <Select
                label='Program Name'
                value={identifier.program}
                onHeChange={(e: any) => {
                    identifier.program = e.target.value
                    onChanged(identifier)
                    setIsNextButtonDisabled({ ...isNextButtonDisabled, BaseDetailsDisabled: baseRelationshipFieldsDisabled() })
                }}
                onHeInput={(e: any) => {
                    identifier.program = e.target.value
                    onChanged(JSON.parse(JSON.stringify(identifier)))
                    setIsNextButtonDisabled({ ...isNextButtonDisabled, BaseDetailsDisabled: baseRelationshipFieldsDisabled() })
                }}
                required
            >
                <Option value='Unknown'>{ProgramType.Unknown}</Option>
                <Option value='CSP'>{ProgramType.CSP}</Option>
                <Option value='FedGovIndirect'>{ProgramType.FedGovIndirect}</Option>
                <Option value='Direct'>{ProgramType.Direct}</Option>
                <Option value='ISV'>{ProgramType.ISV}</Option>
                <Option value='MciWorkshop'>{ProgramType.MciWorkshop}</Option>
                <Option value='GDAP'>{ProgramType.GDAP}</Option>
            </Select>
            <Select
                value={(identifier.relationshipType) ? identifier.relationshipType : ''}
                label='Relationship Type'
                onHeChange={(e: any) => {
                    identifier.relationshipType = e.target.value
                    onChanged(identifier)
                    setIsNextButtonDisabled({ ...isNextButtonDisabled, BaseDetailsDisabled: baseRelationshipFieldsDisabled() })
                }}
                onHeInput={(e: any) => {
                    identifier.relationshipType = e.target.value
                    onChanged(identifier)
                }}
                required
            >
                <Option value='' style={{ height: '30px' }} />
                {(identifier.program) ?
                    FilterRelationshipType[identifier.program].map((type: RelationshipType, index: number) => {
                        return (
                            <Option key={index} value={type}>{type}</Option>
                        )
                    })
                    :
                    FilterRelationshipType.All.map((type: RelationshipType, index: number) => {
                        return (
                            <Option key={index} value={type}>{type}</Option>
                        )
                    })
                }
            </Select>
            <TextField
                label='FpoCaid'
                value={identifier.fpocaid as string}
                onHeChange={(e: any) => {
                    identifier.fpocaid = e.target.value.trim()
                    onChanged(identifier)
                }}
                onHeInput={(e: any) => {
                    identifier.fpocaid = e.target.value.trim()
                    onChanged(identifier)
                }}
            />
            <TextField
                className="margin-top"
                label='Domain Name'
                value={identifier.domainName as string}
                onHeChange={(e: any) => {
                    identifier.domainName = e.target.value.trim()
                    onChanged(identifier)
                }}
                onHeInput={(e: any) => {
                    identifier.domainName = e.target.value.trim()
                    onChanged(identifier)
                }}
            />
            <TextField
                label='Source Friendly Name'
                value={identifier.sourceFriendlyName as string}
                onHeChange={(e: any) => {
                    identifier.sourceFriendlyName = e.target.value.trim()
                    onChanged(identifier)
                }}
                onHeInput={(e: any) => {
                    identifier.sourceFriendlyName = e.target.value.trim()
                    onChanged(identifier)
                }}
            />
            <TextField
                label='Target Friendly Name'
                value={identifier.targetFriendlyName as string}
                onHeChange={(e: any) => {
                    identifier.targetFriendlyName = e.target.value.trim()
                    onChanged(identifier)
                }}
                onHeInput={(e: any) => {
                    identifier.targetFriendlyName = e.target.value.trim()
                    onChanged(identifier)
                }}
            />
        </>
    )
}