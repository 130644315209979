import React, { useState } from 'react';
import RelationshipTable from '../components/relationships/RelationshipTable';
// import '../components/relationships/Relationships.css';
import { AccountType, Endpoint, Page, ProgramType, RelationshipType, FilterRelationshipType, Role } from '../models/Relationships';
import { searchErrors } from '../helperFunctions/SearchHelper';
import RelationshipCreate from '../components/relationships/RelationshipCreate';
import { Button, MessageBar, Select, Option, TextField, Tabs, Tab, TabPanel } from '../components/harmony';
import { RelationshipServiceClient } from '../clients/RelationshipClients';


// Depending on user role this file returns either two tabs(Get and Update, Create) with search textboxes
// or it only returns the search textboxes to Get relationships
export function Relationships(props: any) {
    const [endpoint, setEndpont] = useState<Endpoint>(Endpoint.Source);
    const [id, setId] = useState<string>('');
    const [programType, setProgramType] = useState<ProgramType>('' as ProgramType);
    const [relationshipType, setRelationshipType] = useState<RelationshipType>();
    const [accountType, setAccountType] = useState<AccountType>();
    const [organizationId, setOrganizationId] = useState<string>("");
    const [pages, setPages] = useState<Page[]>([]);
    const [errorMsg, setErrorMsg] = useState<string>();
    const [showError, setShowError] = useState<boolean>(false);
    const [pageNumber, setPageNumber] = useState<number>(0);
    const [nextPageLink, setNextPageLink] = useState<string>('');
    const relationshipServiceClient = new RelationshipServiceClient();
    const [createPanelOpen, setCreatePanelOpen] = useState<boolean>(false);


    const getAllRelationships = async () => {
        setShowError(false);
        if (relationshipType === undefined || programType === undefined || id.trim() === "") {
            setErrorMsg("Please select all fields");
            setShowError(true);

            return
        }

        try {
            const response = await relationshipServiceClient.getRelationships(endpoint, id, programType, relationshipType, accountType ?? AccountType.unknown, organizationId, props.accessToken, props.idToken);
            setPages([response.data]);
            setPageNumber(0);
            setNextPageLink(response.data.nextPageLink)

            if (response.data.relationships.length === 0) {
                setErrorMsg("No relationships were found for this query");
                setShowError(true);
            }
        }
        catch (err: any) {
            setShowError(true);
            setErrorMsg(searchErrors(err?.response?.status));
        }
    }

    const idLabel = () => {
        if (!programType) return 'ID'
        return (programType === 'CSP' || programType === 'ISV' || programType === "GDAP") ?
            'Tenant ID' :
            'Account ID'
    }

    const isSearchDisabled = id === "" || (organizationId === "" && ['FedGovIndirect'].includes(programType))

    const getAndUpdateRelationships = () => {
        return <div>
            <table className="table table-plain">
                <tbody>
                    <tr>
                        <td className="remove-bottom-margin">
                            <Select
                                value={endpoint}
                                label='Search By'
                                onHeChange={(e: any) => { setEndpont(e.target.value as Endpoint) }} >
                                <Option value='source'>Source</Option>
                                <Option value='target'>Target</Option>
                            </Select>
                        </td>
                        <td className="remove-bottom-margin">
                            <Select
                                required
                                label='Program Name'
                                value={(programType) ? programType : ''}
                                onHeChange={(e: any) => {
                                    setProgramType(e.target.value as ProgramType)
                                    setRelationshipType("" as RelationshipType)
                                    setAccountType(undefined)
                                }}
                            >
                                <Option value='CSP'>CSP</Option>
                                <Option value='FedGovIndirect'>FedGovIndirect</Option>
                                <Option value='Direct'>Direct</Option>
                                <Option value='ISV'>ISV</Option>
                                <Option value='MciWorkshop'>MciWorkshop</Option>
                                <Option value='GDAP'>GDAP</Option>
                            </Select>
                        </td>
                        <td className="remove-bottom-margin">
                            <Select
                                required
                                value={(relationshipType) ? relationshipType : ''}
                                label='Relationship Type'
                                onHeChange={(e: any) => { setRelationshipType(e.target.value as RelationshipType) }}
                            >
                                <Option value='' style={{ height: '30px' }} />

                                {(programType) ?
                                    FilterRelationshipType[programType].map((type: RelationshipType, index: number) => {
                                        return (
                                            <Option key={index} value={type}>{type}</Option>
                                        )
                                    })
                                    :
                                    FilterRelationshipType.All.map((type: RelationshipType, index: number) => {
                                        return (
                                            <Option key={index} value={type}>{type}</Option>
                                        )
                                    })
                                }
                            </Select>
                        </td>
                        <td className="remove-bottom-margin">

                            <Select
                                required={programType === 'MciWorkshop'}
                                value={accountType}
                                label='Account Type'
                                disabled={(programType !== 'MciWorkshop')}

                                onHeChange={(e: any) => { setAccountType(e.target.value as AccountType) }}
                            >
                                <Option value='MPN'>MPN</Option>
                                <Option value='TPID'>TPID</Option>
                                <Option value='Tenant'>Tenant</Option>

                            </Select>
                        </td>
                    </tr>
                    <tr>
                        <td className="remove-bottom-margin">
                            <TextField
                                required
                                label={idLabel()}
                                value={id}
                                onHeChange={(e: any) => { setId(e.target.value.trim()) }}
                                onHeInput={(e: any) => { setId(e.target.value.trim()) }}
                            >
                            </TextField>
                        </td>
                        <td className="remove-bottom-margin">
                            <TextField
                                label={'Organization ID'}
                                value={organizationId}
                                onHeChange={(e: any) => { setOrganizationId(e.target.value.trim()) }}
                                onHeInput={(e: any) => { setOrganizationId(e.target.value.trim()) }}
                                disabled={programType !== 'Direct' && programType !== 'FedGovIndirect'}
                            >
                            </TextField>
                        </td>
                        <td className="remove-bottom-margin">
                            <Button
                                className="padding-top"
                                appearance='primary'
                                disabled={isSearchDisabled}
                                onClick={() => getAllRelationships()}
                            >Search</Button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <MessageBar appearance='error' open={showError}>{errorMsg}</MessageBar>

            {(pages.length > 0) &&
                <RelationshipTable
                    pages={pages}
                    setPages={setPages}
                    setPageNumber={setPageNumber}
                    setNextPageLink={setNextPageLink}
                    pageNumber={pageNumber}
                    nextPageLink={nextPageLink}
                    endpoint={endpoint}
                    roles={props.roles}
                    idToken={props.idToken}
                    accessToken={props.accessToken}
                    id={id}
                />
            }
        </div>
    }


    return (
        (props.roles && props.roles.includes(Role)) ?
            <>
                <Tabs style={{ backgroundColor: 'rgb(42 62 82)' }} size='large'>
                    <Tab style={{ color: 'white', outline: 'none' }}> {"Get and Update"}</Tab>
                    <Tab >
                        <Button
                            style={{ color: 'white', outline: 'none' }}
                            appearance='stealth'
                            onClick={() => { setCreatePanelOpen(!createPanelOpen) }}
                        >{"Create"}
                        </Button>
                    </Tab>
                    <TabPanel style={{ backgroundColor: 'white' }}>
                        {getAndUpdateRelationships()}
                    </TabPanel>
                    <TabPanel style={{ backgroundColor: 'white', }}>
                        {createPanelOpen && <RelationshipCreate
                            idToken={props.idToken}
                            accessToken={props.accessToken}
                            panelOpen={createPanelOpen}
                            setPanelOpen={setCreatePanelOpen}
                        />
                        }
                    </TabPanel>
                </Tabs>
            </>
            : getAndUpdateRelationships()
    )
}