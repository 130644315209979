export const searchErrors = (status: number) => {
    switch (status) {
        case 500:
        case 503:
            return "The server encountered an unexpected error, or timed out, and could not complete your request. Please reduce your time range and try again."
        case 401:
            return "You are not authenticated. Please refresh the page and login again."
        case 403:
            return "You are not authorized to perform this action. Please check that you have the right permissions and try again."
        case 404:
            return "No records found."
        case 400:
            return "There was an error in your request. Please fix it, or provide all required parameters, and try again."
        default:
            return "There was an issue completing your request. Additionally, check that you have internet connection and try again."
    }
}

export function formatDate(date: string) {
    const shortMonths = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    var d = new Date(date),
        month = shortMonths[d.getUTCMonth()],
        day = "" + d.getUTCDate(),
        year = d.getUTCFullYear(),
        hour = "" + d.getUTCHours(),
        minute = "" + d.getUTCMinutes(),
        seconds = "" + d.getUTCSeconds();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    if (hour.length < 2) hour = "0" + hour;
    if (minute.length < 2) minute = "0" + minute;
    if (seconds.length < 2) seconds = "0" + seconds;

    var finalString = month + " " + day + ", " + year + " " + [hour, minute, seconds].join(":");
    return finalString;
}

export async function downloadData(recordToShow: any, id: string) {
    if (recordToShow !== undefined) {
        const fileName = recordToShow[id];
        const json = JSON.stringify(recordToShow);
        const blob = new Blob([json], { type: "application/json" });
        const href = await URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = href;
        link.download = fileName + ".json";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
}

export function isGuid(str: string){
    const guidRegex = new RegExp("^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$", "i");
    return guidRegex.test(str);
}

export function createGuid(str: string){
    return `{${str}}`
}

/*
export function findDiff(arg1: Object, arg2: Object){
    const diff: Object[] = []

    function findDifference(obj1: Object, obj2: Object): boolean{
        if(Object.prototype.toString.call(obj1) === Object.prototype.toString.call(obj2)){
            if(['[Object Object]'].includes(Object.prototype.toString.call(obj1))){

                return Object.keys(obj1).every(key=>findDifference(obj1[key], obj2[key]))

            }
            else{
                return obj1 !== obj2
            }

        }
        return false
    }

    if(!findDifference(arg1, arg2)){
        
    }

}
*/