import axios from "axios";
import { AuditSearchRequestBlob } from "../models/Audit";

export class AuditServiceClient {

    async getAuditRecord(searchRequest: AuditSearchRequestBlob, idToken: string, accessToken: string) {
       const response = await axios.post(
            '/api/auditadmin/audit',
            JSON.stringify(searchRequest),
            {
                headers: {
                    "Content-Type": "application/json",
                    "id-token": idToken,
                    Authorization: `Bearer ${accessToken}`
                }
            }
        );

        return response;
    }

    async getAuditRecordByPagination(searchRequest: AuditSearchRequestBlob, idToken: string, accessToken: string) {
        const response = await axios.post(
             '/api/auditadmin/audit?paginate=true',
             JSON.stringify(searchRequest),
             {
                 headers: {
                     "Content-Type": "application/json",
                     "id-token": idToken,
                     Authorization: `Bearer ${accessToken}`
                 }
             }
         );
 
         return response;
     }

    async getAllWebhooks(domain: string, accessToken: string){
       const response = axios.get(
            `/api/auditadmin/webhooks/${domain}`,
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            }
        )

        return response;
    }

    async executeGPTQuery(query: string, accessToken: string) {
        const response = axios.get(
            `/api/auditadmin/openAIQuery?query=${query}`,
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            }
        )

        return response;
    }
}