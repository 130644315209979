import axios from "axios";

export class CatalogServiceClient {

    async getCatalogCacheResponse(cacheKey: string, accessToken: string) {
        const response = await axios.get(
            '/api/catalogadmin/cachesearch?cacheKey=' + cacheKey,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${accessToken}`
                }
            }
        );

        return response;
    }
}