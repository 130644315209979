import React from "react";
import { IPersonaSharedProps, Persona, PersonaSize } from '@fluentui/react/lib/Persona';

export interface AvatarProps {
    userImageUrl: string;
    displayName: string;
    jobTitle: string;

}

const Avatar = (props: AvatarProps) => {
    const examplePersona: IPersonaSharedProps = {
        imageUrl: props.userImageUrl,
        text: props.displayName,
        secondaryText: props.jobTitle
    };

    return (
        <Persona
            {...examplePersona}
            size={PersonaSize.size40}
            hidePersonaDetails={false}
            styles={{ primaryText: { color: "#f3f2f1" }, secondaryText: { color: "#edebe9" } }}
        />
    );
};

export default Avatar;
