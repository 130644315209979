import * as React from "react";
import { AxiosResponse } from "axios";
import { formatDate, searchErrors } from "../helperFunctions/SearchHelper";
import { AuditServiceClient } from "../clients/AuditClient";
import { AuditRecordDocument, AuditSearchRequestBlob, OperationTypes } from '../models/Audit';
import { TextField, Option, Select, Button, Icon, Tooltip, DatePicker, MessageBar, DataGrid, Pagination, usePagination, Progress } from "../components/harmony"
import '../css/Common.css';
import { useState, useReducer } from "react";
import { Row } from "@harmony/enablers/components/data-grid/data-grid";
import { AuditExpand } from "../components/audit&webhooks/AuditExpand";

export interface IDocument {
    operationDate: any;
}

type auditRecordsReducerAction = 
        { type: "clear_data", value: Row<AuditRecordDocument>[]} 
        | { type: "update_from_query", value: Row<AuditRecordDocument>[]};

export function AuditSearch(props: any) {
    const [loading, setLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [auditSearchListMessage, setAuditSearchListMessage] = useState<string>("");
    const [partnerId, setPartnerId] = useState('');
    const [startTime, setStartTime] = useState("00:00");
    const [endTime, setEndTime] = useState("00:00");
    const [endDate, setEndDate] = useState<string>(new Date().toISOString());
    const [filter, setFilter] = useState("");
    const [customerId, setCustomerId] = useState<string>('');
    const [operationType, setOperationType] = useState<string>('');
    const auditServiceClient = new AuditServiceClient();
    const defaultPageSize = 50;
    const startDateValue = new Date();
    startDateValue.setDate(startDateValue.getDate() - 30);
    const [startDate, setStartDate] = useState<string>(startDateValue.toISOString());
    //const [auditRecords, setAuditRecords] = useState<Row<AuditRecordDocument>[]>([]);
    const [showPanel, setShowPanel] = useState<boolean>(false);
    const [selectedAuditRecord, setSelectedAuditRecord] = useState<AuditRecordDocument>();

    const auditColumns = [
        { field: "partnerId", content: "Partner Id" },
        { field: "customerId", content: "Customer Id" },        
        { field: "resourceType", content: "Resource Type" },
        { field: "operationType", content: "Operation Type" },
        { field: "operationDate", content: "Operation Date" },
    ];

    const auditRecordsReducer = (state: Row<AuditRecordDocument>[], action: auditRecordsReducerAction) => 
    {
        switch (action.type) 
        {
            case "clear_data":
                state = [] as Row<AuditRecordDocument>[];
                return state;
      
            case "update_from_query":
                state = (state ?? [] as Row<AuditRecordDocument>[]).concat(action.value)
                return state;
            
            default:
                return state;
        }
    }

    const [auditRecords, auditRecordsDispatch] = useReducer(auditRecordsReducer, [] as Row<AuditRecordDocument>[]);
    
    // Validates the values entered in the search bar and makes api request.
    const validateAndSubmit = () => {

        if (!searchIsEnabled()) {
            setIsError(true);
            setAuditSearchListMessage("Please enter at least one more search parameter");
            return;
        }
            

        const searchRequest: AuditSearchRequestBlob = {
            StartDate: startDate.substring(0, 10) + " " + startTime,
            EndDate: endDate.substring(0, 10) + " " + endTime,
            TotalRecords: '1000',
        }

        if (partnerId.length > 0) searchRequest.PartnerId = partnerId;
        if (customerId.length > 0) searchRequest.CustomerId = customerId;
        if (operationType.length > 0) searchRequest.OperationType = operationType.replace(/([a-z])([A-Z])/g, '$1_$2').toLowerCase();
        if (filter.length > 0) searchRequest.Filter = filter;

        searchAllRecords(searchRequest);
    }

    const searchIsEnabled = () => {
        return (partnerId.length > 0 || customerId.length > 0 || operationType.length > 0 || filter.length > 0);
    }

    const searchAllRecords = async (searchRequest: AuditSearchRequestBlob) => {
        // set or reset parameters
        setIsError(false)
        setAuditSearchListMessage("");
        auditRecordsDispatch({type: "clear_data", value: [] as Row<AuditRecordDocument>[]});
        setLoading(true);

        // try making the api call
        try 
        {
            var continuationToken = null;
            var noOfRecords = 0
            do
            {
                if (continuationToken != null)
                {
                    searchRequest.ContinuationToken = continuationToken;
                }
                const search: AxiosResponse<any, any> = await auditServiceClient.getAuditRecordByPagination(searchRequest, props.idToken, props.accessToken)

                // if the call went through but the server responded with error, record error and exit
                if (search.data.items === undefined) {
                    setIsError(true)
                    setAuditSearchListMessage(searchErrors(search.data.code))
                    return;
                }

                const data = search.data?.items?.reverse()?? [];
                noOfRecords = noOfRecords + data.length;

                auditRecordsDispatch({type: "update_from_query", value: data.map((item: AuditRecordDocument, idx: number) => {
                    return {
                        id: idx,
                        cells: {
                            partnerId: item.partnerId,
                            customerId: item.customerId,
                            operationType: (item.operationType as string).split('_').join(" "),
                            operationDate: formatDate(item.operationDate),
                            resourceType: (item.resourceType as string).split('_').join(" "),
                        },
                        customData: item,
                    } as Row<AuditRecordDocument>
                })});

                const message: string = `Showing ${noOfRecords} Audit Records`;
                setAuditSearchListMessage(message);
                continuationToken = search.data.continuationToken;
            } while(continuationToken != null);
        }
        catch (err: any) {
            setIsError(true)
            setAuditSearchListMessage(searchErrors(err?.response?.status));
        }
        finally {
            setLoading(false);
        }
    };
    
    const [pagedData, totalPages, currentPage, onPageChange] = usePagination(auditRecords ?? [] as Row<AuditRecordDocument>[], defaultPageSize);

    return (
        <div>
            <div>
                <MessageBar
                    open
                    appearance="notice">
                    Search functionality has been improved! We do not require Partner Tenant ID going forward. Search Audit records just by Customer Tenant ID or any filter attribute such as Subscription ID, Order ID, etc. Additionally, search now returns up to 1000 records.
                </MessageBar>
                <table className="table table-plain">
                    <tbody>
                        <tr>
                            <td className="remove-bottom-margin">
                                <TextField
                                    className="margin-top"
                                    label='Partner Id'
                                    value={partnerId}
                                    onHeChange={(e: any) => { setPartnerId(e.target.value.trim()); }}
                                    onHeInput={(e: any) => { setPartnerId(e.target.value.trim()); }}
                                />
                            </td>
                            <td className="remove-bottom-margin">
                                <DatePicker
                                    required
                                    value={startDate}
                                    onHeChange={(e: any) => setStartDate(new Date(e.target.value).toISOString())}
                                    onHeInput={(e: any) => setStartDate(new Date(e.target.value).toISOString())}
                                    label='Start Date'
                                />
                            </td>
                            <td>
                                <TextField
                                    className="margin-top"
                                    required
                                    value={startTime}
                                    onHeChange={(e: any) => setStartTime(e.target.value)}
                                    onHeInput={(e: any) => setStartTime(e.target.value)}
                                    label='Start Time'
                                    type='time'
                                ></TextField>
                            </td>
                            <td>
                                <DatePicker
                                    required
                                    value={endDate}
                                    placeholder={endDate}
                                    onHeChange={(e: any) => setEndDate(new Date(e.target.value).toISOString())}
                                    onHeInput={(e: any) => setEndDate(new Date(e.target.value).toISOString())}
                                    label='End Date'
                                />
                            </td>
                            <td>
                                <TextField
                                    className="margin-top "
                                    required
                                    value={endTime}
                                    onHeChange={(e: any) => setEndTime(e.target.value)}
                                    onHeInput={(e: any) => setEndTime(e.target.value)}
                                    label='End Time'
                                    type='time'
                                ></TextField>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <TextField
                                    label='Customer Id'
                                    aria-label="Adding a Customer Id gets you results faster."
                                    onHeChange={(e: any) => setCustomerId(e.target.value.trim())}
                                    onHeInput={(e: any) => setCustomerId(e.target.value.trim())}

                                >
                                    <Icon style={{ color: 'primary' }} id="customer-id-icon" slot="end" name="info" />
                                </TextField>
                                <Tooltip anchor="customer-id-icon">
                                    {"Adding a Customer Id gets you results faster."}
                                </Tooltip>
                            </td>
                            <td>
                                <Select
                                    value={operationType}
                                    label='Operation Type'
                                    onHeChange={(e: any) => setOperationType(e.target.value)}
                                >
                                    <Option key="operationtype_0" value={""}>{'Select an operation type'}</Option>
                                    {OperationTypes.sort().map((type, index) => {
                                        return <Option key={index} value={type}>{type}</Option>

                                    })}
                                </Select>
                            </td>
                            <td className="remove-all-margin field-max-width">
                                <TextField
                                    label='Filter'
                                    onHeChange={(e: any) => setFilter(e.target.value)}
                                    onHeInput={(e: any) => setFilter(e.target.value)}
                                />
                            </td>
                            <td>
                                <Button
                                    className="padding-top"
                                    appearance="primary"
                                    onClick={validateAndSubmit}>{"Search"}</Button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            {
                loading && <Progress label="Progress" indeterminate />
            }
            {
                auditSearchListMessage.length > 0 && <MessageBar open appearance={isError ? "error" : "success"}>{auditSearchListMessage}</MessageBar>
            }
            {
                showPanel && <AuditExpand
                    selectedAuditRecord={selectedAuditRecord}
                    setShowPanel={setShowPanel}
                    setSelectedAuditRecord={setSelectedAuditRecord}
                    showPanel={showPanel}
                />
            }
            <DataGrid 
                columns={auditColumns} 
                rows={pagedData}
                select = "single"
                select-on-click
                label="Select a row to expand"
                onHeRowSelectChange={(event) => {
                    setSelectedAuditRecord((event.detail as Row<AuditRecordDocument>).customData); 
                    setShowPanel(true);
                }}
            >
                <span slot="no-records">No records.</span>
                <Pagination slot="pagination" pageCount={totalPages} value={currentPage} onHePageChange={onPageChange}/>
            </DataGrid>
        </div>
    );
}
