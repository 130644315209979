import React, { useRef, useState } from 'react';
import { Account, EntityIdentifierPlaceHolder, ExtendedProperty, ExtendedPropertyPlaceholder, ProgramType, Relationship, RelationshipBasePlaceholder } from '../../models/Relationships';
import { Button, Icon, Wizard, WizardItem } from '../harmony';
import { RelationshipServiceClient } from '../../clients/RelationshipClients';
import { searchErrors } from '../../helperFunctions/SearchHelper';
import { FlyInPanel } from '../harmony/FlyInPanel';
import EntityIdentifier from './create_relationship/EntityIdentifier';
import ExtendedPropertyV2 from './create_relationship/ExtendedProperty';
import { BaseRelationship } from './create_relationship/BaseRelationship';
import { ReviewRelationship } from './create_relationship/ReviewRelationship';

export interface DisableNextWizardButton {
    BaseDetailsDisabled: boolean,
    SourceAccountDisabled: boolean,
    TargetAccountDisabled: boolean,
    RelatedThroughDisabled: boolean,
    ExtendedPropertiesDisabled: boolean,
}

const RelationshipCreate = (props: any) => {
    const [errorMsg, setErrorMsg] = useState('');
    const wizardRef = useRef<any>();
    const [isNextButtonDisabled, setIsNextButtonDisabled] = useState<DisableNextWizardButton>({
        BaseDetailsDisabled: true,
        SourceAccountDisabled: true,
        TargetAccountDisabled: true,
        RelatedThroughDisabled: false,
        ExtendedPropertiesDisabled: true,
    } as DisableNextWizardButton);
    const [relatedThroughs, setRelatedThroughs] = useState<Account[]>([]);
    const [extendedProperties, setExtendedProperties] = useState<ExtendedProperty[]>([{ ...ExtendedPropertyPlaceholder }]);
    const [sourceAccount, setSourceAccount] = useState<Account>({ ...EntityIdentifierPlaceHolder });
    const [targetAccount, setTargetAccount] = useState<Account>({ ...EntityIdentifierPlaceHolder });
    const [relationshipBase, setRelationshipBase] = useState<Relationship>({ ...RelationshipBasePlaceholder });
    const [createdRelationship, setCreatedRelationship] = useState<Relationship>();
    const relationshipServiceClient = new RelationshipServiceClient();

    let relationship: Relationship = {
        ...relationshipBase,
        source: sourceAccount,
        target: targetAccount,
        relatedThrough: relatedThroughs,
        extendedProperties: extendedProperties,
    }

    const createRelationship = async () => {

        try {
            const response = await relationshipServiceClient.createRelationship(relationship, props.accessToken, props.idToken)
            if (response.data.error) {
                setErrorMsg(response.data.message)
                wizardRef.current?.throwFullWizardError()
            } else {
                relationship = { ...response.data }
                setCreatedRelationship(response.data)
                wizardRef.current?.complete()
            }
        }
        catch (err: any) {
            if (err?.response?.status === 400) {
                setErrorMsg(`There was an error trying to create this relationship. \n ${err?.response?.data?.ParamName} `)
            }
            else {
                setErrorMsg(`There was an error trying to create this relationship. \n ${searchErrors(err?.response?.status)}`)
            }
            wizardRef.current?.throwFullWizardError()
            // setShowError(true)
        }
    }

    const disabledIdentityFields = (account: Account): boolean => {
        const accountIdLength = account.accountId.trim().length
        const organizationIdLength = account.organizationId.trim().length
        const pairedFields = !((accountIdLength > 0 && organizationIdLength > 0) || (accountIdLength === 0 && organizationIdLength === 0))

        const tenantIdCheck = tenantIdRequired && account.tenantId.trim() === ''
        const accountIdCheck = accountIdRequired && account.accountId.trim() === ''

        return pairedFields || tenantIdCheck || accountIdCheck;
    }

    const disabledExtendedPropertyFields = (property: ExtendedProperty): boolean => {
        return property.key.trim() === '' || property.value.trim() === ''
    }

    // set required fields based on program type
    const accountIdRequired: boolean = [ProgramType.Direct, ProgramType.FedGovIndirect, ProgramType.MciWorkshop].includes(relationship.program.trim() as ProgramType)
    const tenantIdRequired: boolean = [ProgramType.CSP, ProgramType.ISV, ProgramType.GDAP].includes(relationship.program.trim() as ProgramType)

    return (
        <div style={{ height: "100vh" }}>

            <FlyInPanel
                style={{ '--size': '940px', '--body-padding': ' 0px;' }}
                open={props.panelOpen}
                onHeAfterHide={() => props.setPanelOpen(false)}
                onHeRequestClose={() => { }}
            >
                <Wizard
                    completeOnError={true}
                    next-label="Next"
                    previous-label="Back"
                    review-label="Create"
                    done-label="Done"
                    wizard-title="Create Relationship"
                    failureTitle={errorMsg}
                    successTitle="Relationship created successfully!"
                    ref={wizardRef}
                    onHeClose={() => {
                        props.setPanelOpen(false);
                    }}
                    onHeComplete={async (event: any) => {
                        event.preventDefault();
                        await createRelationship();
                    }}
                >
                    <WizardItem
                        navLabel='Base Relationship'
                        disableNext={isNextButtonDisabled.BaseDetailsDisabled}
                        stepTitle={`Add Base Relationship`}
                    >
                        <BaseRelationship
                            identifier={relationshipBase}
                            onChanged={setRelationshipBase}
                            setIsNextButtonDisabled={setIsNextButtonDisabled}
                            isNextButtonDisabled={isNextButtonDisabled}
                        />
                    </WizardItem>
                    <WizardItem
                        navLabel='Source Identifier'
                        disableNext={isNextButtonDisabled.SourceAccountDisabled}
                        stepTitle={`Add Source Identifier`}
                    >
                        <EntityIdentifier
                            identifier={sourceAccount}
                            program={relationship.program}
                            showDeleteOption={false}
                            onChanged={(account) => {
                                setSourceAccount(account);
                                setIsNextButtonDisabled({ ...isNextButtonDisabled, SourceAccountDisabled: disabledIdentityFields(account) });
                            }
                            } />
                    </WizardItem>
                    <WizardItem
                        navLabel='Target Identifier'
                        disableNext={isNextButtonDisabled.TargetAccountDisabled}
                        stepTitle={`Add Target Identifier`}
                    >
                        <EntityIdentifier
                            identifier={targetAccount}
                            program={relationship.program}
                            showDeleteOption={false}
                            onChanged={(account) => {
                                setTargetAccount(account);
                                setIsNextButtonDisabled({ ...isNextButtonDisabled, TargetAccountDisabled: disabledIdentityFields(account) })
                            }} />
                    </WizardItem>
                    <WizardItem
                        navLabel='Related Through'
                        disableNext={isNextButtonDisabled.RelatedThroughDisabled}
                        stepTitle={`Add Related Through`}
                    >
                        <Button
                            appearance='stealth'
                            onClick={() => {
                                const newRelatedThrough = [...relatedThroughs, { ...EntityIdentifierPlaceHolder } as Account]
                                setRelatedThroughs(newRelatedThrough)
                                setIsNextButtonDisabled({ ...isNextButtonDisabled, RelatedThroughDisabled: newRelatedThrough.length > 0 && newRelatedThrough.some(account => disabledIdentityFields(account)) })
                            }}
                        >
                            <Icon name='addmedium' style={{ padding: "5px", color: "white", backgroundColor: 'rgb(42 62 82)' }}></Icon> {"Add Related Through"}
                        </Button>
                        {
                            relatedThroughs.map((relatedThrough: any) => {
                                return (
                                    <EntityIdentifier
                                        identifier={relatedThrough}
                                        program={relationship.program}
                                        showDeleteOption={true}
                                        Delete={(account) => {
                                            const newRelatedThrough = relatedThroughs.filter((item) => item !== account)
                                            setRelatedThroughs(newRelatedThrough)
                                            setIsNextButtonDisabled({ ...isNextButtonDisabled, RelatedThroughDisabled: newRelatedThrough.length > 0 && newRelatedThrough.some(account => disabledIdentityFields(account)) })
                                        }
                                        }
                                        onChanged={(account) => {
                                            const index = relatedThroughs.indexOf(account);
                                            relatedThroughs[index] = account;
                                            setRelatedThroughs([...relatedThroughs]);
                                            setIsNextButtonDisabled({ ...isNextButtonDisabled, RelatedThroughDisabled: relatedThroughs.length > 0 && relatedThroughs.some(account => disabledIdentityFields(account)) })
                                        }}
                                    />)
                            })
                        }
                    </WizardItem>
                    <WizardItem
                        navLabel='Extended Properties'
                        disableNext={isNextButtonDisabled.ExtendedPropertiesDisabled}
                        stepTitle={`Add Extended Properties`}
                    >
                        <>
                            <Button
                                className='margin-top'
                                appearance='stealth'
                                onClick={() => {
                                    const newExtendedProperties = [...extendedProperties, { ...ExtendedPropertyPlaceholder } as ExtendedProperty]
                                    setExtendedProperties(newExtendedProperties)
                                    setIsNextButtonDisabled({ ...isNextButtonDisabled, ExtendedPropertiesDisabled: newExtendedProperties.length > 0 && newExtendedProperties.some(property => disabledExtendedPropertyFields(property)) })
                                }}
                            >
                                <Icon name='addmedium' style={{ padding: "5px", color: "white", backgroundColor: 'rgb(42 62 82)' }}></Icon> {"Add Extended Properties"}
                            </Button>
                            {
                                extendedProperties.map((extendedProperty: ExtendedProperty) => {
                                    return (<ExtendedPropertyV2
                                        extendedProperty={extendedProperty}

                                        Delete={(property: ExtendedProperty) => {
                                            const newExtendedProperties = extendedProperties.filter((item) => item !== property)
                                            setExtendedProperties(newExtendedProperties)
                                            setIsNextButtonDisabled({ ...isNextButtonDisabled, ExtendedPropertiesDisabled: newExtendedProperties.length > 0 && newExtendedProperties.some(property => disabledExtendedPropertyFields(property)) })
                                        }
                                        }
                                        onChanged={(extendedProperty: ExtendedProperty) => {
                                            const index = extendedProperties.indexOf(extendedProperty);
                                            extendedProperties[index] = extendedProperty;
                                            setExtendedProperties([...extendedProperties])
                                            setIsNextButtonDisabled({ ...isNextButtonDisabled, ExtendedPropertiesDisabled: extendedProperties.some(property => disabledExtendedPropertyFields(property)) })
                                        }
                                        }
                                    />)
                                })
                            }
                        </>
                    </WizardItem>
                    <WizardItem
                        navLabel='Review'
                        stepTitle={`Review`}
                    >
                        <ReviewRelationship
                            relationship={relationship}
                            filter={["createdDateTime", "relationshipId"]}
                        />
                    </WizardItem>
                    <span slot='success-message'>
                        {createdRelationship && <ReviewRelationship
                            relationship={createdRelationship}
                            filter={[]}
                        />
                        }
                    </span>
                </Wizard>
            </FlyInPanel>
        </div >
    )
}

export default RelationshipCreate