import { flyInPanel } from "@harmony/enablers/react";
import { Scope } from "./Scope";

export const FlyInPanel = Scope.forReact(flyInPanel);

export const WizardForm : any = {
    '--size': '1280px', '--body-padding': ' 0px;'
}

export const PreventClose = (event: any) : void => {
    if (event.target === event.currentTarget && event.detail.source === 'overlay') {
      event.preventDefault();
    }
  }