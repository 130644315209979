import React, { useState } from 'react';
import WorkshopsEligibilityTable from './WorkshopsEligibilityTable';
import { searchErrors } from '../../helperFunctions/SearchHelper';
import { Eligibility } from '../../models/Workshops';
import { Button, DataGrid, MessageBar, Progress, TextField } from '../harmony';
import { WorkshopsServiceClient } from '../../clients/WorkshopsClient';
import { Column, Row } from '@harmony/enablers/components/data-grid/data-grid';

export function WorkshopsEligibilityChecker(props: any) {
    const [partnerId, setPartnerId] = useState<string>('');
    const [customerId, setCustomerId] = useState<string>('');
    const [engagementId, setEngagementId] = useState<string>('');
    const [searchMsg, setSearchMsg] = useState<string>("");
    const [showError, setShowError] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [eligibilities, setEligibilities] = useState<Eligibility[]>([]);
    const [partnerName, setPartnerName] = useState<string>('');
    const [customerName, setCustomerName] = useState<string>('');
    const [relationshipExists, setRelationshipExists] = useState<boolean>(false);
    const workshopsServiceClient = new WorkshopsServiceClient();

    const searchDisabled: boolean = customerId === "" || partnerId === ""

    const columns: Column[] = [
        { field: "partnerName", content: " Partner Name" },
        { field: "customerName", content: "Customer Name" },
        { field: "relationshipExists", content: "Relationship Exists" },
    ]

    const rows: Row[] = [{
        id: 1,
        cells: {
            partnerName: partnerName,
            customerName: customerName,
            relationshipExists: relationshipExists.toString()
        }
    }]

    const searchEligibility = async () => {
        setShowError(false);
        setIsLoading(true);
        setSearchMsg("");
        setEligibilities([]);
        setPartnerName("");
        setCustomerName("");
        setRelationshipExists(false);
        
        // search with provided parameters.
        try {
            const eligibilityResponse = await workshopsServiceClient.searchEligibility(partnerId, customerId, engagementId, props.accessToken)
            if (eligibilityResponse.data.items.length === 0) {
                setSearchMsg("No eligibilities were found for this query");
                setShowError(true);
            } else {
                setSearchMsg(`${eligibilityResponse.data.items.length} eligibilities were found for this query`);
                setEligibilities(eligibilityResponse.data.items);
                setPartnerName(eligibilityResponse.data.partnerName);
                setCustomerName(eligibilityResponse.data.customerName);
                setRelationshipExists(eligibilityResponse.data.relationshipExists)

            }
        }
        catch (err: any) {
            setShowError(true);
            setSearchMsg(searchErrors(err?.response?.status));
        }
        finally {
            setIsLoading(false);
        }
    };

    const getEligibility = () => {
        return <React.Fragment>
            <table className="table table-plain">
                <tbody>
                    <tr>
                        <td className="remove-bottom-margin">
                            <TextField
                                required
                                className="margin-top"
                                label='Partner MPN ID'
                                value={partnerId}
                                onHeChange={(e: any) => { setPartnerId(e.target.value.trim()); }}
                                onHeInput={(e: any) => { setPartnerId(e.target.value.trim()); }}
                            />
                        </td>
                        <td>
                            <TextField
                                value={customerId}
                                label="Customer Tenant ID/ TPID"
                                onHeChange={(e: any) => { setCustomerId(e.target.value.trim()) }}
                                onHeInput={(e: any) => { setCustomerId(e.target.value.trim()) }}
                                required
                            >
                            </TextField>
                        </td>
                        <td>
                            <TextField
                                value={engagementId}
                                label="Engagement ID"
                                onHeChange={(e: any) => { setEngagementId(e.target.value.trim()) }}
                                onHeInput={(e: any) => { setEngagementId(e.target.value.trim()) }}
                            >
                            </TextField>
                        </td>
                        <td>
                            <Button
                                className="padding-top"
                                appearance='primary'
                                disabled={searchDisabled}
                                onClick={() => searchEligibility()}
                            >{"Search"}</Button>
                        </td>
                    </tr>
                </tbody>
            </table>
            {isLoading && <Progress indeterminate />}
            {searchMsg.length > 0 && <MessageBar open appearance={showError ? "error" : "success"}>{searchMsg}</MessageBar>}
            {eligibilities.length > 0 &&
                <div>
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        fixedHeading
                    >
                        <div slot="data-grid-controls" style={{ justifyContent: "center", display: 'flex' }}>
                            {"ELIGIBILITY INFORMATION"}
                        </div>
                    </DataGrid>
                    <WorkshopsEligibilityTable eligbilities={eligibilities} />
                </div>}
        </React.Fragment>
    }

    return getEligibility()
}