import { Dictionary } from "./utility";

export const OperationTypes = [
    'UpdateCustomerQualification',
    'UpdateSubscription',
    'UpgradeSubscription',
    'ConvertTrialSubscription',
    'AddCustomer',
    'UpdateCustomerBillingProfile',
    'UpdateCustomerPartnerContractCompanyName',
    'UpdateCustomerSpendingBudget',
    'DeleteCustomer',
    'RemovePartnerCustomerRelationship',
    'CreateOrder',
    'UpdateOrder',
    'CreateCustomerUser',
    'DeleteCustomerUser',
    'UpdateCustomerUser',
    'UpdateCustomerUserLicenses',
    'ResetCustomerUserPassword',
    'UpdateCustomerUserPrincipalName',
    'RestoreCustomerUser',
    'CreateMpnAssociation',
    'UpdateMpnAssociation',
    'UpdateSfbCustomerUserLicenses',
    'UpdateTransfer',
    'CreatePartnerRelationship',
    'RegisterApplication',
    'UnregisterApplication',
    'AddApplicationCredential',
    'RemoveApplicationCredential',
    'CreatePartnerUser',
    'UpdatePartnerUser',
    'RemovePartnerUser',
    'CreateReferral',
    'UpdateReferral',
    'GetSoftwareKey',
    'GetSoftwareDownloadLink',
    'IncreaseSpendingLimit',
    'ReadyInvoice',
    'CreateAgreement',
    'ExtendRelationship',
    'CreateTransfer',
    'CreateSelfServePolicy',
    'UpdateSelfServePolicy',
    'DeleteSelfServePolicy',
    'RemovePartnerRelationship',
    'DeleteTipCustomer',
    'CreateRelatedReferral',
    'UpdateRelatedReferral',
    'GranularAdminRelationshipCreated',
    'GranularAdminRelationshipApproved',
    'GranularAdminRelationshipActivated',
    'GranularAdminRelationshipTerminationRequested',
    'GranularAdminRelationshipTerminated',
    'GranularAdminRelationshipExpired',
    'GranularAdminAccessAssignmentCreated',
    'GranularAdminAccessAssignmentUpdated',
    'GranularAdminAccessAssignmentDeleted',
    'GranularAdminRelationshipUpdated',
    'GranularAdminRelationshipAutoExtended',
    'DapAdminRelationshipApproved',
    'DapAdminRelationshipTerminated',
    'CreateCredit',
    'UpdateCredit',
    'CancelCredit',
    'AddUserMember',
    'RemoveUserMember',
    'GranularAdminAccessAssignmentActivated',
    'SoftwareAttestation',
    'GranularAdminRelationshipCleanedUp',
    'UpdateCompanyInfo',
    'PolicyCreated',
    'PolicyUpdated',
    'PolicyDeleted',
    'DeviceUpdated',
    'DevicesUpdateAttempted',
    'DeviceDeleted',
    'DevicesUploadToNewBatchAttempted',
    'DevicesUploadToExistingBatchAttempted',
    'ManageOverage',
    'DapAdminRelationshipTerminatedByMicrosoft',
    'AzureFraudEventDetected',
    'AzureFraudEventMarkedInvestigating',
    'AzureFraudEventResolved',
    'CreateNewCommerceMigration',
    'CompleteNewCommerceMigration',
    'NewCommerceMigrationFailed',
    'NewCommerceMigrationScheduleFailed',
    'CreateNewCommerceMigrationSchedule',
    'UpdateNewCommerceMigrationSchedule',
    'CancelNewCommerceMigrationSchedule',
    'CancelAzureEntitlement',
    'CreateEmail',
    'ResellerRelationshipAcceptedByCustomer',
    'ImportLicenseSummary',
    'UpdateLicenseSummary',
    'IndirectResellerRelationshipAcceptedByCustomer',
    'IndirectResellerRelationshipCreatedByPartner',
    'ReactivateAzureEntitlement',
    'CompleteTransfer',
    'FailTransfer',
    'SubscriptionActive',
    'SubscriptionPending',
    'SubscriptionRenewed',
    'SubscriptionSuspended',
    'SubscriptionDeleted',
    'SubscriptionExpired',
    'SubscriptionDisabled'
]

export const ResourceTypes = [
    'Customer',
    'CustomerUser',
    'Order',
    'Subscription',
    'License',
    'ThirdPartyAddOn',
    'MpnAssociation',
    'Transfer',
    'Application',
    'ApplicationCredential',
    'PartnerUser',
    'Referral',
    'SoftwareKey',
    'SoftwareDownloadLink',
    'SpendingLimit',
    'Invoice',
    'Agreement',
    'PartnerCustomerRelationship',
    'SelfServePolicy',
    'GranularAdminRelationship',
    'GranularAdminAccessAssignment',
    'PartnerCustomerDap',
    'Credit',
    'CustomerDirectoryRole'
]

export interface AuditSearchRequestBlob {
    PartnerId?: string;
    CustomerId?: string;
    ResourceType?: string;
    OperationType?: string;
    Filter?: string;
    StartDate?: string;
    EndDate?: string;
    TotalRecords?: string;
    ContinuationToken?: string;
}

export interface AuditRecordDocument {
    id: string;
    partnerId: string;
    customerId: string;
    operationType: string;
    resourceType: string;
    operationDate: string;
    operationStatus: string;
    originalCorrelationId: string;
    participants: string;
    customerName: string;
    userPrincipalName: string;
    applicationId: string;
    sessionId: string;
    resourceOldValue: string;
    resourceNewValue: string;
    resourceUri: string;
    customizedData: Dictionary<string>[];
    Attributes: Dictionary<string>;
}