import React from 'react';
import { Button } from '../harmony';

export function LoginBanner(props: any){
    return (
        <div style={{ backgroundColor: 'rgb(42 62 82)', padding: 30, borderRadius: 5 }} >
            <div>
                <h3 style={{ color: 'white', fontWeight: 'lighter' }}  >{"Welcome"}</h3>
            </div>
            <div>
                <h6 style={{ color: 'white', fontWeight: 'lighter' }} >{"You must sign in to view Partner Center SMRT Tool"}</h6>
                <Button
                    appearance='primary'
                    onClick={async () => { await props.login(); window.location.reload() }}
                >
                    {" Click Here to Sign In"}
                </Button>
            </div>
        </div >
    )
}

