import React, { useState } from "react";
import { Button, DataGrid, MessageBar, Progress, TextField } from "../harmony";
import { CustomerServiceClient } from "../../clients/CustomerServiceClient";
import { AxiosResponse } from "axios";
import { Column, Row } from "@harmony/enablers/components/data-grid/data-grid";
import { searchErrors } from "../../helperFunctions/SearchHelper";

export function MigrateRelationshipStatus(props: any) {

    const [requestId, setRequestId] = useState("");
    const customerServiceClient = new CustomerServiceClient();
    const [statusRows, setStatusRows] = useState<Row[]>([]); // get rows from API's response
    const [requestIdRows, setRequestIdRows] = useState<Row[]>([]); // get rows from API's response
    const [createdDateTime, setCreatedDateTime] = useState<string>(); // get rows from API's response
    const [lastActionDateTime, setLastActionDateTime] = useState<string>(); // get rows from API's response
    const [isloading, setIsloading] = useState(false);
    const [requestIdSearchErrorMessage, setRequestIdSearchErrorMessage] = useState("");

    const requestIdcolumns: Column[] = [
        { field: "requestId", content: "Request Id", sortable: true },
        { field: "createdTimestamp", content: "Created Timestamp", sortable: true },
        { field: "upn", content: "User Alias", sortable: true },
        { field: "sourcePartnerId", content: "Source PartnerId", sortable: true },
        { field: "targetPartnerId", content: "Target PartnerId", sortable: true },
        { field: "customerIds", content: "Customer Ids", sortable: true },
    ];

    const statusColumns = [
        { field: "sourcePartnerId", content: "Source PartnerId", sortable: true },
        { field: "targetPartnerId", content: "Target PartnerId", sortable: true },
        { field: "customerId", content: "Customer Id", sortable: true },
        { field: "nationalCloud", content: "National Cloud", sortable: true },
        { field: "status", content: "Status", sortable: true },
        { field: "error", content: "Error", sortable: true },
    ];

    // call API to check status 
    const checkStatus = async () => {

        setIsloading(true)
        setStatusRows([]);
        setCreatedDateTime("");
        setLastActionDateTime("");
        setRequestIdSearchErrorMessage("");

        try {
            const response: AxiosResponse = await customerServiceClient.checkMigrateRelationshipStatus(requestId, props.accessToken, props.idToken);
            setIsloading(false);

            if (response?.data?.operations) {
                const rows = response.data.operations?.map((item: any, idx: number) => {
                    return {
                        id: idx,
                        cells: {
                            sourcePartnerId: item.sourcePartnerId,
                            relatedThrough: item.relatedThrough,
                            targetPartnerId: item.targetPartnerId,
                            customerId: item.customerId,
                            relationshipType: item.relationshipType,
                            nationalCloud: item.nationalCloud,
                            status: item.status,
                            error: item?.error?.message ?? "No error"
                        }
                    }
                });

                setStatusRows(rows);
                setCreatedDateTime(response.data.createdDateTime);
                setLastActionDateTime(response.data.lastActionDateTime);
            }
            else {
                setRequestIdSearchErrorMessage(
                    "No data found for this request id. Please check the request id and try again. Or escalate if issue persists.")
            }
        }
        catch (e: any) {
            setIsloading(false);
            setRequestIdSearchErrorMessage(searchErrors(e?.response?.status));
        }
    }

    // call API to get all request ids
    const getRequestIds = async () => {

        setIsloading(true);
        setRequestIdRows([]);
        setRequestIdSearchErrorMessage("");
        try {
            const response: any = await customerServiceClient.getRequestIDs(props.accessToken, props.idToken);
            setIsloading(false);

            if (response.data) {
                const parentRows = response.data.map((item: any, idx: number) => {
                    return {
                        id: `${idx}`,
                        cells: {
                            requestId: item?.requestId,
                            createdTimestamp: item.createdTimestamp,
                            upn: item.upn,
                            sourcePartnerId: item.sourcePartnerId,
                            targetPartnerId: item.targetPartnerId,
                            customerIds: JSON.parse(item.customerIds).length.toString()
                        }
                    }
                });

                const childRows = response.data.map((item: any, idx: number) => {
                    const customerIds = JSON.parse(item.customerIds);
                    return customerIds.map((customerId: string, child_idx: number) => {
                        return {
                            id: `${idx}+${child_idx}`,
                            parentId: `${idx}`,
                            cells: {
                                customerIds: customerId
                            }
                        }
                    }).flat();
                });

                setRequestIdRows(parentRows.concat(childRows).flat());
            }
        }
        catch (e: any) {
            setIsloading(false);
            setRequestIdSearchErrorMessage(searchErrors(e?.response?.status));
        }
    }

    return (
        <>
            <div>
                <p >To check the status of a migration request, you need a request id (which was provided as part of the migration).
                    If you don't remember the request id, you have two options: </p>
                <ol>
                    <li> <Button appearance="link" onClick={getRequestIds}><a>Click here</a></Button> to get all the request ids of the requests you made on this site and then copy the desired request id.</li>
                    <li>Head over to the Relationships tab and check if the relationship exists. That would confirm it was migrated</li>
                </ol>
            </div >
            <form>
                <table style={{ maxWidth: "100%" }}>
                    <tbody>
                        <tr>
                            <td>
                                <TextField
                                    label="Request Id"
                                    value={requestId}
                                    onHeChange={(e: any) => { setRequestId(e?.target?.value.trim()) }}
                                    onHeInput={(e: any) => { setRequestId(e?.target?.value.trim()) }}
                                    disabled={false}
                                ></TextField>
                            </td>
                            <td>
                                <Button
                                    style={{ marginTop: "1.4rem" }}
                                    appearance='primary'
                                    onClick={checkStatus}
                                >
                                    {"Submit"}
                                </Button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </form>
            <br />
            {isloading && <Progress indeterminate />}
            {requestIdSearchErrorMessage.length > 0 && <MessageBar appearance="error" open>{requestIdSearchErrorMessage}</MessageBar>}
            {requestIdRows.length > 0 && <DataGrid columns={requestIdcolumns} rows={requestIdRows} />}
            {statusRows.length > 0 && <DataGrid columns={statusColumns} rows={statusRows}>
                <div slot="data-grid-controls" >
                    {createdDateTime && <MessageBar appearance="notice" open noCloseButton>{`Created DateTime:  ${createdDateTime}`}</MessageBar>}
                    {lastActionDateTime && <MessageBar appearance="notice" open noCloseButton>{`Last Action DateTime:   ${lastActionDateTime}`}</MessageBar>}
                </div>
            </DataGrid>}
        </>
    )
}