import React from "react";
import { FlyInPanel } from "../harmony/FlyInPanel";
import ReactJson from "react-json-view";
import { Button, Icon } from "../harmony";
import { downloadData } from "../../helperFunctions/SearchHelper";

export function AuditExpand(props: any) {
    const  auditRecord = props.selectedAuditRecord;

    if(auditRecord["resourceOldValue"] &&
    auditRecord["resourceOldValue"] !== "" &&
    typeof auditRecord["resourceOldValue"] === "string"){
        auditRecord["resourceOldValue"] = JSON.parse(auditRecord["resourceOldValue"]);
    }

    if(auditRecord["resourceNewValue"] &&
    auditRecord["resourceNewValue"] !== "" &&
    typeof auditRecord["resourceNewValue"] === "string"){
        auditRecord["resourceNewValue"] = JSON.parse(auditRecord["resourceNewValue"]);
    }

    const closePanel = () => {
        props.setShowPanel(false); 
        props.setSelectedAuditRecord(undefined);
    }

    return (
        <FlyInPanel
            heading = "Audit Record"
            onHeAfterHide={() => { closePanel(); }}
            open={props.showPanel}
            style={{ 
                "--size": "var(--he-panel-size-large)"
            }}
        >    
            <ReactJson
                src={auditRecord}
                theme="ocean"
                collapsed={1}
                displayDataTypes={false}
                displayObjectSize={false}
                indentWidth={8}
            />
            <div slot="footer"
                style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: 'var(--he-spacing-x-small)',
                    alignItems: 'center',
                }}
            >                    
                <Button slot="footer" appearance="primary" onClick={() => { downloadData(auditRecord, "id"); }}>
                    Download<Icon name="download" slot ="end" />
                </Button>
                <Button slot="footer" appearance="primary" onClick={() => { closePanel(); }}>
                    Close<Icon name="cancel" slot= "end"/>
                </Button>
            </div>
        </FlyInPanel>

    )
}