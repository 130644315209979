import { Column, Row } from "@harmony/enablers/components/data-grid/data-grid";
import React from "react";
import { Button, DataGrid, MessageBar, Progress } from "../harmony";
import { createGuid, isGuid, searchErrors } from "../../helperFunctions/SearchHelper";
import { Pagination, usePagination } from "../harmony/Pagination";
import { CustomerServiceClient } from "../../clients/CustomerServiceClient";

export function MigrateRelationships(props: any) {
    const successMessage = "Migration started successfully. Please save and use the following to check the status of the migration:";
    const allowedHeaders = ['SourcePartnerId', 'TargetPartnerId', 'CustomerTenantId', 'RelatedThrough']
    const [columns, setColumns] = React.useState<Column[]>([]);
    const [rows, setRows] = React.useState<Row[]>([]);

    const [fileFormatValidationError, setFileFormatValidationError] = React.useState(false);
    const [fileMaxRecordCountValidationError, setFileMaxRecordCountValidationError] = React.useState(false);
    const [fileHeadersNotFoundValidationError, setFileHeadersNotFoundValidationError] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [migrationMessage, setMigrationMessage] = React.useState("");
    const [migrationError, setMigrationError] = React.useState(false);

    const customerServiceClient = new CustomerServiceClient();

    const createColumns = (fileContentLines: string[]) => {
        let columns: Column[] = [];
        let headers = fileContentLines.map(item => { return item.replace(/\\/g, "").replace(/"/g, "").replace(/'/g, "").replace(/\s/g, ""); });
        for (let i = 0; i < Math.min(5, headers.length); i++) {

            let column: Column = {
                field: headers[i],
                content: headers[i],
                sortable: true,
            };
            columns.push(column);
        }
        let column: Column = {
            field: "AllGuids",
            content: "AllGuids",
            sortable: true,
        };

        columns.push(column);
        setColumns(columns);
        return columns;
    }

    const createRows = (fileContentLines: string[]) => {
        let rows: Row[] = [];

        for (let i = 1; i < fileContentLines.length; i++) {

            let currentLine = fileContentLines[i].split(",");
            let row: Row = {
                id: i,
                cells: {
                }
            };

            let isValidGuid = true;

            for (let j = 0; j < fileContentLines[0].split(",").length; j++) {
                const col = fileContentLines[0]?.split(",")[j]?.replace(/\\/g, "")?.replace(/"/g, "")?.replace(/'/g, "")?.replace(/\s/g, "");
                const val = currentLine[j]?.replace(/\\/g, "")?.replace(/"/g, "")?.replace(/'/g, "")?.replace(/\s/g, "");
                isValidGuid = isGuid(val);

                if (!isValidGuid && col !== "RelatedThrough") {
                    setFileFormatValidationError(true);
                }

                row["cells"][col] = val;
            }
            row["cells"]["AllGuids"] = isValidGuid.toString();
            rows.push(row);
        }
        setRows(rows);
        return rows;
    }

    const validateCsv = (fileContent: string) => {
        var fileContentLines = fileContent.split(/\r\n|\n/).filter((str) => !str.match(/^[,\s]*$/));
        var fileHeaders = fileContentLines[0].split(",");

        //Make sure the CSV file contains no more than 501 records (500 records plus a header) before proceeding
        if (fileContentLines.length > 501 || fileContentLines.length < 2) {
            setFileMaxRecordCountValidationError(true);
        }

        if (!fileHeaders.every(header => allowedHeaders.includes(header))) {

            setFileHeadersNotFoundValidationError(true)
        }

        createColumns(fileHeaders);
        createRows(fileContentLines);
    };

    const onUploadFile = (file: File) => {
        const fr = new FileReader();
        fr.onload = (e) => {
            const fileContent = e.target?.result as string;
            validateCsv(fileContent);
        };
        fr.readAsText(file);
    };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFileMaxRecordCountValidationError(false);
        setFileHeadersNotFoundValidationError(false);
        setFileFormatValidationError(false);
        setRows([]);

        if (e.target.files) {
            onUploadFile(e.target.files[0]);
        }
    }

    const startMigration = async () => {
        setIsLoading(true);
        // call the API to start the migration
        const request = rows.map(row => {
            return {
                SourcePartnerId: createGuid(row.cells["SourcePartnerId"]),
                TargetPartnerId: createGuid(row.cells["TargetPartnerId"]),
                CustomerId: createGuid(row.cells["CustomerTenantId"]),
                RelatedThrough: row.cells["RelatedThrough"] !== undefined && row.cells["RelatedThrough"].trim() !== "" ? createGuid(row.cells["RelatedThrough"].trim()) : undefined,
            }
        });

        try {
            const startMigration = await customerServiceClient.migrateRelationships(request, props.accessToken, props.idToken);
            if (startMigration?.status === 200) {
                setMigrationMessage(`${successMessage + startMigration?.data ?? ""}`);
                setMigrationError(false);
            }
            else {
                setMigrationError(true);
                setMigrationMessage(startMigration?.data.message)
            }
        }
        catch (e: any) {
            setMigrationError(true);
            setMigrationMessage(searchErrors(e?.response?.status));
        }
        finally {
            setIsLoading(false);
        }
    }

    const migrationBtnDisabled = fileFormatValidationError || fileMaxRecordCountValidationError || fileHeadersNotFoundValidationError;
    const [pagedData, totalPages, currentPage, onPageChange] = usePagination(rows, 25);

    return (
        <>
            <div>
                <div>
                    Guidelines for the file to be uploaded:
                    <ul>
                        <li> <MessageBar open no-close-button> File must be a CSV file</MessageBar></li>
                        <li><MessageBar appearance={fileHeadersNotFoundValidationError ? "error" : "info"} open no-close-button> File must contain the header rows: SourcePartnerId,TargetPartnerId,CustomerTenantId, and  optionally, a RelatedThrough.</MessageBar></li>
                        <li><MessageBar appearance={fileMaxRecordCountValidationError ? "error" : "info"} open no-close-button> File must contain at least 1 data row and no more than 1000 data rows</MessageBar></li>
                        <li><MessageBar appearance={fileFormatValidationError ? "error" : "info"} open no-close-button> Since all rows are Tenant Ids, they must be GUIDs</MessageBar></li>
                    </ul>
                </div>
                Note: Only PartnerCustomer relationships will be migrated. Migrating other relationship types is not currently supported.
                <div>
                    <input type="file" accept=".csv" onChange={handleFileChange} />
                </div>
                <br />
                {isLoading && <Progress indeterminate />}
                {migrationMessage && <MessageBar open no-close-button appearance={migrationError ? "error" : "success"}>{migrationMessage}</MessageBar>}
                <div>
                    <DataGrid rows={pagedData} columns={columns}>
                        <span slot="no-records">{"No Rows"}</span>
                        <Pagination slot="pagination"
                            pageCount={totalPages} value={currentPage}
                            onHePageChange={onPageChange}
                        /></DataGrid>
                </div>
                <div>
                    <Button appearance="primary" disabled={migrationBtnDisabled} onClick={startMigration}>Start Migration</Button>
                </div>
            </div>
        </>
    )
}