import * as React from "react";
// import "./Common.css";
import '../../css/Common.css';

import { DetailsListLayoutMode, IColumn } from "@fluentui/react/lib/DetailsList";
import { ShimmeredDetailsList } from "@fluentui/react/lib/ShimmeredDetailsList";
import { SelectionMode, Selection } from "@fluentui/utilities";

export interface AuditSearchListProps {
    allRecords: any[];
    onSelect: ((record: any) => void);
    recordToShow: any;
    loading: boolean;
    auditSearchListMessage: string;
    isError: boolean;
}

export interface IDocument {
    id: any;
}

export function WebHooksSearchList({
    allRecords,
    onSelect,
    loading,
}: AuditSearchListProps) {
    const columns: IColumn[] = [
        {
            key: "column1",
            name: "Subscriber ID",
            fieldName: "id",
            minWidth: 150,
            maxWidth: 250,
            isRowHeader: true,
            isResizable: false,
            data: "string",
            isPadded: true,
        },
    ];

    const [selectionDetails, setSelectionDetails] = React.useState<any>("Not Found");
    const [selection, setSelecion] = React.useState<Selection>(() => {
        return new Selection({
            onSelectionChanged: () => {
                let output: any;
                if (selection.getSelectedCount() > 0) {
                    output = (selection.getSelection()[0] as IDocument).id;
                } else {
                    output = 1;
                }
                setSelectionDetails(output);
                onSelect(output);
            },
        });
    });

    function _generateDocuments() {
        const items: IDocument[] = [];
        allRecords.map((record: any) => {
            items.push({ id: record.id });
        });
        return items;
    }

    return (
        <>
            <div style={{

                overflowY: "auto",
                maxHeight: "80vh",
            }} >
                <ShimmeredDetailsList
                    enableShimmer={loading}
                    disableSelectionZone={false}
                    items={_generateDocuments()}
                    setKey="set"
                    compact={false}
                    columns={columns}
                    selectionMode={SelectionMode.single}
                    layoutMode={DetailsListLayoutMode.justified}
                    isHeaderVisible={true}
                    selection={selection}
                    selectionPreservedOnEmptyClick={true}
                    enterModalSelectionOnTouch={true}
                    ariaLabelForSelectionColumn="Toggle selection"
                    ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                    checkButtonAriaLabel="Row checkbox"
                    minimumPixelsForDrag={5000000}
                />
            </div>
        </>
    );
}
