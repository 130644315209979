import axios from "axios";

export class CustomerServiceClient {

    /**
     * Migrate IP-C relationships from PA to PB.
     */
    async migrateRelationships(migrateRequest: any, accessToken: string, idToken: string) {
        const response = axios.post(
            '/api/migration/migrate',
            JSON.stringify(migrateRequest),
            {
                headers: {
                    "Content-Type": "application/json",
                    "id-token": idToken,
                    Authorization: `Bearer ${accessToken}`
                }
            }
        )

        return response;
    }

    /**
    * Check status of Migrated IP-C relationships.
    */
    async checkMigrateRelationshipStatus(requestId: string, accessToken: string, idToken: string) {
        const response = axios.get(
            '/api/migration/status',
            {
                params: {
                    requestId: requestId,
                },
                headers: {
                    "Content-Type": "application/json",
                    "id-token": idToken,
                    Authorization: `Bearer ${accessToken}`
                }
            }
        )

        return await response;
    }

    /**
     * get request ids for previously run requests.
     */
    async getRequestIDs(accessToken: string, idToken: string) {
        const response = axios.get(
            '/api/migration/requestIds',
            {
                headers: {
                    "Content-Type": "application/json",
                    "id-token": idToken,
                    Authorization: `Bearer ${accessToken}`
                }
            }
        )

        return await response;
    }
}