import React from "react";
import { Select, TextField, Option, Button } from "../harmony";
import '../../css/Common.css';

interface MyProps {
    onSearch: (type: string, partnerId: string) => void
    typeParameter: string
    partnerIDParameter: string
    allRecords: any
}

export function WebHooksSearchBar({ onSearch, typeParameter, partnerIDParameter, allRecords }: MyProps) {
    const [partnerId, setPartnerId] = React.useState("");
    const [type, setType] = React.useState("");
    React.useEffect(() => {
        if (
            typeParameter != undefined
        ) {
            setType(typeParameter);
            setPartnerId(partnerIDParameter);

        }
    }, []);

    const validateAndSubmit = () => {
        if (type !== "") {
            onSearch(type, partnerId)
        }
    }

    return (
        <div>
            <table className="table table-plain justify-content-center">
                <tbody>
                    <tr>
                        <td className="remove-bottom-margin">
                            <Select
                                style={{ width: "var(--he-width-small)" }}
                                required
                                label='Type'
                                value={type}
                                onHeChange={(e: any) => setType(e.target.value)}
                            >
                                <Option value='partner'>
                                    Partner
                                </Option>
                                <Option value='developer'>
                                    Developer
                                </Option>
                                <Option value='internalapp'>
                                    Internal App
                                </Option>
                            </Select>
                            <br />
                        </td>
                        <td  className="remove-bottom-margin">
                            <TextField
                                style={{ width: "var(--he-width-small)" }}

                                label='Suscriber ID'
                                onHeChange={(e: any) => setPartnerId(e.target.value)}

                            />
                            <br />
                        </td>
                        <td className="remove-bottom-margin">
                            <Button
                                className="padding-top"
                                type='submit'
                                disabled={type === ""}
                                appearance="primary"
                                onClick={validateAndSubmit}
                            >
                                Search
                            </Button>
                        </td>
                    </tr>
                </tbody>
            </table>
            
        </div>
    );
}
