import React from 'react';
import { Tab, Tabs, TabPanel } from '../components/harmony';
import { MigrateRelationshipStatus } from '../components/relationshipMigration/MigrateRelationshipStatus';
import { MigrateRelationships } from '../components/relationshipMigration/MigrateRelationships';

export function RelationshipMigration(props: any) {
    return <>
        <Tabs style={{ backgroundColor: 'rgb(42 62 82)' }} size='large'>
            <Tab style={{ color: 'white', outline: 'none' }}> {"Migrate Relationships"}</Tab>
            <Tab style={{ color: 'white', outline: 'none' }}>{"Check Status"}</Tab>
            <TabPanel style={{ backgroundColor: 'white' }}>
                <MigrateRelationships {...props}/>
            </TabPanel>
            <TabPanel style={{ backgroundColor: 'white', }}>
                <MigrateRelationshipStatus  {...props} />
            </TabPanel>
        </Tabs>
    </>
}