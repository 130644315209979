import React from 'react';
import { WorkshopsEmailTracker } from '../components/workshops/WorkshopsEmailTracker';
import { WorkshopsEligibilityChecker } from '../components/workshops/WorkshopsEligibilityChecker';
import { Tab, Tabs, TabPanel } from '../components/harmony';

export function Workshops(props: any) {
    return <>
        <Tabs style={{ backgroundColor: 'rgb(42 62 82)' }} size='large'>
            <Tab style={{ color: 'white', outline: 'none' }}> {"Eligibility"}</Tab>
            <Tab style={{ color: 'white', outline: 'none' }}>{"Emails"}</Tab>
            <TabPanel style={{ backgroundColor: 'white' }}>
                <WorkshopsEligibilityChecker accessToken={props.accessToken} />
            </TabPanel>
            <TabPanel style={{ backgroundColor: 'white', }}>
                <WorkshopsEmailTracker accessToken={props.accessToken} />
            </TabPanel>
        </Tabs>
    </>
}