import axios from "axios";
import { AccountType, DeleteRelationshipRequest, Relationship, RelationshipType } from "../models/Relationships";

export class RelationshipServiceClient {

    /**
     * Create relationship.
     */
    async createRelationship(newRelationship: Relationship, accessToken: string, idToken: string) {
        const response = axios.post(
            '/api/relationship/createRelationship',
            JSON.stringify(newRelationship),
            {
                headers: {
                    "Content-Type": "application/json",
                    "id-token": idToken,
                    Authorization: `Bearer ${accessToken}`
                }
            }
        )

        return response;
    }

    /**
     * Update relationship.
     */
    async updateState(relationship: Relationship, accessToken: string, idToken: string) {

        const response = axios.post(
            '/api/relationship/updateState',
            JSON.stringify(relationship),
            {
                headers: {
                    "Content-Type": "application/json",
                    "id-token": idToken,
                    Authorization: `Bearer ${accessToken}`
                }

            }
        )

        return response;
    };

    /**
     * Updated name of the relationship
     * 
     * @param newName 
     * @param accessToken 
     * @param idToken 
     * @returns 
     */
    async updateName(newName: any, accessToken: string, idToken: string) {
        const response = axios.post(
            '/api/relationship/updateName',
            JSON.stringify(newName),
            {
                headers: {
                    "Content-Type": "application/json",
                    "id-token": idToken,
                    Authorization: `Bearer ${accessToken}`
                }
            }
        )

        return response;
    }

    /**
  * Deletes a relationship
  * @param relationship the relationship object to be deleted.
  */
    async deleteRelationship(deleteRelationshipRequest: DeleteRelationshipRequest, accessToken: string, idToken: string) {

        const response = axios.delete(
            '/api/relationship/deleteRelationship',
            {
                data: JSON.stringify(deleteRelationshipRequest),
                headers: {
                    "Content-Type": "application/json",
                    "id-token": idToken,
                    Authorization: `Bearer ${accessToken}`
                }
            }
        )

        return response;
    }

    /**
     * Search for all relationships for given id and program type.
     */
    async getRelationships(endpoint: string, id: string, programType: string, relationshipType: RelationshipType, accountType: AccountType, organizationId: string, accessToken: any, idToken: any) {

        const response = axios.get(
            '/api/relationship/getRelationships',
            {
                params: {
                    endpoint: endpoint,
                    id: id,
                    program: programType,
                    relationshipType: relationshipType,
                    accountType: accountType,
                    organizationId: organizationId
                },
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }

            }
        )

        return response;

    };

    /**
     * Gets the next page of the relationships data.
     * 
     */
    async getNextPage(nextPageLink: string, accessToken: string) {

        const response = await axios.get(
            '/api/relationship/getNextPage',
            {
                headers: {
                    "continuation-Token": nextPageLink,
                    Authorization: `Bearer ${accessToken}`
                },

            }
        )
        return response;
    };

    async executeGPTQuery(query: string, accessToken: string) {
        const response = axios.get(
            `/api/relationship/openAIQuery?query=${query}`,
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            }
        )

        return response;
    }
}