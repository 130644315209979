import * as React from "react";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Navbar, NavbarBrand, NavItem, NavLink } from "reactstrap";
import { Link } from "react-router-dom";
import "./NavMenu.css";
import Avatar from "./Avatar";

export function NavMenu(props: any) {
  const [auditDropdownOpen, setAuditDropdownOpen] = React.useState(false);
  const [relationshipDropdownOpen, setRelationshipDropdownOpen] = React.useState(false);
  const [logoutOpen, setLogoutOpen] = React.useState(false);

  return (
    <header>
      <Navbar style={{ backgroundColor: '#343a40' }} className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" light>
        <div className='navbar-container'>
          <NavbarBrand className="text-light" tag={Link} to="/">
            Partner Center SMRT Tool
          </NavbarBrand>
          {(props.isAuthenticated) ?
            <ul className="navbar-nav flex-grow">
              <NavItem>
                <NavLink tag={Link} className="text-light" to="/">
                  Home
                </NavLink>
              </NavItem>
              <>
                <NavItem>
                  <Dropdown
                    isOpen={auditDropdownOpen}
                    onMouseOver={() => setAuditDropdownOpen(true)}
                    onMouseLeave={() => setAuditDropdownOpen(false)}>

                    <DropdownToggle style={{ "backgroundColor": "transparent", "color": "white", "borderColor": "transparent" }}>
                      Audit
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem tag={Link} to="https://dataexplorer.azure.com/clusters/adxauditsvcprod.eastus2/databases/AuditServiceData">Kusto Search</DropdownItem>
                      <DropdownItem divider />
                      <DropdownItem tag={Link} to="/AuditSearch">Search</DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </NavItem>
                <NavItem>
                  <NavLink tag={Link} className="text-light" to="/WebHooks">
                    WebHooks
                  </NavLink>
                </NavItem>
              </>
              <NavItem>
                <Dropdown
                  isOpen={relationshipDropdownOpen}
                  onMouseOver={() => setRelationshipDropdownOpen(true)}
                  onMouseLeave={() => setRelationshipDropdownOpen(false)}>

                  <DropdownToggle style={{ "backgroundColor": "transparent", "color": "white", "borderColor": "transparent" }}>
                    Relationships
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem tag={Link} to="/RelationshipSearchGPT">GPT Search</DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem tag={Link} to="/Relationships">Search</DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </NavItem>
              {props.roles !== undefined && props.roles?.includes("Catalog-READ") &&
                <NavItem>
                  <NavLink tag={Link} className="text-light" to="/Catalog">
                    Catalog
                  </NavLink>
                </NavItem>
              }
              {props.roles?.includes("Relationship-BulkMigrate") &&
                <NavItem>
                  <NavLink tag={Link} className="text-light" to="/RelationshipMigration">
                    RelationshipMigration
                  </NavLink>
                </NavItem>
              }
              <NavItem>
                <NavLink tag={Link} className="text-light" to="/Workshops">
                  Workshops
                </NavLink>
              </NavItem>
              <NavItem className="navitem" style={{ "padding": 0 }}>
                <Dropdown
                  isOpen={logoutOpen}
                  onMouseOver={() => setLogoutOpen(true)}
                  onMouseLeave={() => setLogoutOpen(false)}>
                  <DropdownToggle style={{ "backgroundColor": "transparent", "color": "black", "borderColor": "transparent", "padding": 0 }}>
                    <Avatar userImageUrl={props.userImageUrl} displayName={props.displayName} jobTitle={props.jobTitle} />
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem id="navitem" onClick={() => props.logout()}>Logout</DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </NavItem>
            </ul  >
            :
            <ul className="navbar-nav flex-grow">
              <NavItem>
                <NavLink tag={Link} className="text-light" onClick={async () => { await props.login(); }} to="/">
                  Log In
                </NavLink>
              </NavItem>
            </ul>
          }
        </div>
      </Navbar>
    </header>
  );
}
