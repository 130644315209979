import * as React from "react";
import Routing from "./Routing";
import "./custom.css";
import { ThemeProvider } from "@material-ui/styles";
import { createTheme } from "@material-ui/core";
import { NavMenu } from "./components/navigationbar/NavMenu";
import AuthProvider from "./components/authentication/AuthProvider";

function App(props: any) {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#00a2ed",
      },
      secondary: {
        main: "#FF0000",
      },
    },
    typography: {
      button: {
        textTransform: "none",
      },
      fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"'
    },
  });

  return (
    <div>
      <ThemeProvider theme={theme}>
        <NavMenu {...props} />
        <Routing {...props} />
      </ThemeProvider>
    </div>
  );
}

export default AuthProvider(App);